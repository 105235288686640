@import '../../../../../style/variables.less';

.resizableEditor {
  width: 100%;
  padding: 5px 10px;
  min-height: 150px;
  resize: vertical;
  overflow: auto;
  border: none;
  font-size: 14px;
  outline: none;
  font-family: 'Basier Circle';
  line-height: 24px;
}

.resizableEditor::placeholder {
  color: rgba(179, 178, 178, 1);
}

.pause_menu {
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin: 1px 0;
}

.toolbarButton {
  position: absolute;
  left: 50%;
  bottom: 16px;
  min-width: 200px;
  background: rgba(217, 217, 217, 0.15);
  border-radius: @default-border-radius;
  transform: translateX(-50%);
  .ant-btn {
    display: flex;
    align-items: center;
    span {
      padding-top: 2px;
    }
  }
}

.speechWrapper {
  position: relative;
  padding: 18px 45px 65px 60px;
  border: 1px solid rgba(189, 189, 189, 0.3);
  border-radius: 8px;
  transition: all 0.3s, height 0s;

  &.focus {
    box-shadow: 0 0 0 2px rgba(5, 88, 255, 0.06);
    border-inline-end-width: 1px;
  }
}

.toolbarIcon {
  margin-right: 5px;
  height: 17px;
}

.contextMenuIcon {
  height: 17px;
}

.characterLimit {
  display: flex;
  margin-top: 5px;
  font-size: 12px;
  color: @title-dark-grey-color;
  flex-direction: row-reverse;
}

.phoneme-menu {
  .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 0;
    .item-content {
      padding: 5px 12px;
    }

    .ant-dropdown-menu-title-content span:first-child {
      margin-right: 8px;
    }
  }
}

.phoneme-popover {
  .ant-popover-inner {
    padding: 0;
    box-shadow: none;

    .phoneme-input-wrapper {
      position: relative;

      .ant-input-compact-first-item.phoneme-input {
        border-start-start-radius: 4px;
        border-end-start-radius: 4px;
        border-start-end-radius: 4px;
        border-end-end-radius: 4px;
      }

      .btn-close {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -7px;
        right: -7px;
        color: @dark-color;
        font-size: 12px;
        background-color: #f3f3f3;
        height: 16px;
        width: 16px;
        min-width: 16px;
        padding: 0;
        border-start-end-radius: 50%;
        border-end-end-radius: 50%;
        border: none;
        box-shadow: none;
        z-index: 1;

        .icon {
          transform: none;
        }
      }
    }
  }
}


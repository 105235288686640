@import '../../style/variables.less';

.avatars-content {
  .library-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .title {
      margin: 0;
    }

    .btn-filters .icon {
      font-size: 16px;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .ant-empty {
    margin: 30px auto;
  }

  .avatar-cards-row {
    margin-bottom: 40px;

    & > {
      .ant-col {
        width: 20%;
      }
    }

    .ant-card {
      border: none;
      border-radius: @border-radius-middle;
      height: 100%;
    }

    .create-avatar-card {
      .ant-card-body {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 30px;

        h4 {
          font-size: 18px;
          font-weight: 700;
          line-height: 20px;
          margin-bottom: 24px;
        }

        img {
          display: block;
          width: 85%;
        }

        .main-content-wrapper {
          display: flex;
          justify-content: space-between;
          flex: 1 1 auto;

          .btn-buy-avatar {
            align-self: flex-end;

            .icon {
              font-size: 18px;
            }
          }
        }
      }
    }

    .avatar-card {
      .ant-card-body {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        padding: 24px 16px 16px;

        img {
          display: block;
          width: 73%;
          border-radius: @border-radius-middle;
          margin: 0 auto 16px;
        }

        .default-avatar-thumbnail {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 86px;
          width: 73%;
          aspect-ratio: 1/1;
          color: @icon-dark-grey-color;
          margin: 0 auto;
        }

        .card-tag {
          left: -6px;
        }

        .btn-add-data {
          margin: auto 16px 0;
        }
      }
    }
  }

  .avatar-name-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;

    p {
      font-weight: 500;
      line-height: 17px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
    }

    .btn-create-video {
      min-width: 24px;
      box-shadow: none;
    }

    .btn-delete-avatar {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        color: @icon-dark-grey-color;
        font-size: 16px;
      }

      &:hover {
        background-color: @error-light-color;

        .icon {
          color: @error-color;
        }
      }
    }
  }

  .avatar-intro-wrapper {
    position: relative;
    aspect-ratio: 1.27;
    border-radius: @border-radius-middle;
    margin-bottom: 8px;
    overflow: hidden;

    video {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
}

.premium-avatar-card-mixin() {
  display: flex;
  align-items: center;
  gap: 4px;
  color: white;
  font-weight: 500;
  border: 0;
  font-size: 11px;
  background-color: @primary-color;

  .icon {
    font-size: 14px;
  }
}

.premium-card {
  position: absolute;
  top: 8px;
  right: 15px;
  .premium-avatar-card-mixin();
}

.premium-card-sidebar {
  margin-inline-end: 6px;
  border-radius: 7px;
  padding-inline: 4px;
  .premium-avatar-card-mixin();
}

@media screen and (max-width: 1200px) {
  .avatars-content .avatar-cards-row {
    & > {
      .ant-col {
        width: 25%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .avatars-content .avatar-cards-row {
    & > {
      .ant-col {
        width: 33%;
      }
    }
  }
}

@media screen and (max-width: 825px) {
  .avatars-content .avatar-cards-row {
    & > {
      .ant-col {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .avatars-content .avatar-cards-row {
    & > {
      .ant-col {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .avatars-content .avatar-cards-row {
    & > {
      .ant-col {
        width: 50%;
      }
    }

    .avatar-card .btn-add-data {
      margin: auto 0;
    }
  }
}


@import '../../style/variables.less';

#workspaces-menu {
  background-color: @btn-primary-hover-color;
  border-color: @btn-primary-hover-color;
  border-radius: @border-radius-middle;
}

#workspaces-menu-content {
  border-radius: @border-radius-middle;
  background-color: @btn-primary-hover-color;
  padding-bottom: 1px;
  ul {
    background-color: transparent;
    // border-radius: 0;
    padding: 0;
    li {
      background-color: transparent;
      border-radius: 0;
      padding: 12px;
      color: #ffffff;
      border-bottom: 2px solid rgba(255, 255, 255, 0.1);
      font-size: 16px;

      .workspaces-menu-content-title {
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        gap: 8px;

        svg {
          width: 12px;
          height: 12px;
          color: @primary-color;
        }
      }
    }
  }
  .workspaces-menu-content-add-button {
    margin: 12px;
    border: 0;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      color: @icon-grey-color;
      span {
        background-color: @icon-grey-color;
      }
    }

    span {
      background-color: #ffffff;
      padding: 2px;
      width: 14px;
      height: 14px;
      border-radius: @border-radius-xsmall;
      display: flex;
      justify-content: center;
      svg {
        color: @primary-color;
        width: 12px;
        height: 12px;
      }
    }
  }
}

#workspaces-menu-button {
  background-color: @btn-primary-hover-color;
  border-color: @btn-primary-hover-color;
  border-radius: @border-radius-middle;
  height: 48px;
  color: #ffffff;
  padding: 12px;
  margin: 0 4px 12px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .workspaces-menu-button-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


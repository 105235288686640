/* HOW TO
  .selector {
    display: flex;
    .media-max(960px, {
      display: none;
    });
  }
*/
.media-max(@break_point; @rules) {
  @media screen and (max-width: @break_point) {
    @rules();
  }
}

.media-max-height(@break_point; @rules) {
  @media screen and (max-height: @break_point) {
    @rules();
  }
}

.media-min(@break_point; @rules) {
  @media screen and (min-width: @break_point) {
    @rules();
  }
}

.media-range(@break_point_from; @break_point_to; @rules) {
  @media screen and (min-width: @break_point_from) and (max-width: @break_point_to) {
    @rules();
  }
}

/* HOW TO
  // it declares @rem var at that scope where mixin has been called
  .selector { 
    .rem(30px);
    font-size: @rem;
  }
  // how to use it with other root size value
  .selector { 
    .rem(30px; 20px);
    font-size: @rem;
  }
*/

.rem(@pixels; @base_font_size: 16px) {
  @rem: unit((@pixels / @base_font_size), rem);
}

/* HOW TO
  // for font-size just pass pixel value inside the mixin
  .selector { 
    .rem-property(30px);
  }
  // for changing property name just pass it as a second argument
  .selector { 
    .rem-property(30px; margin-bottom);
  }
  // for changing root size value pass it as a third arg, but then you should specify a second arg also
  .selector { 
    .rem-property(30px; margin-bottom; 20px);
  }
*/
.rem-property(@pixels; @property: font-size; @base_font_size: 16px) {
  @{property}: unit((@pixels / @base_font_size), rem);
}

// for setting font-size in rem just use this mixin
// .rem-property() is more generic, but this one is most common
.rem-font(@pixels; @base_font_size: 16px) {
  .rem-property(@pixels, font-size, @base_font_size);
}

// just pass font size in pixels and set up a layoutWidth size (whole page size from Figma or Photoshop)
// by default 1440px is a size of a layoutWidth
.responsive-font-size(@pxSize, @layoutWidth: 1440px, @responsiveRules: {}) {
  font-size: unit((@pxSize * 100 / @layoutWidth), vw);
  @responsiveRules();
}

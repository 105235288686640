@import '../../../style/variables.less';

.continue-poll-button:hover {
  background-color: @primary-color !important;
  border-color: @primary-color !important;
}

@media (hover: hover) {
  .continue-poll-button:hover {
    background-color: @btn-primary-hover-color !important;
    border-color: @btn-primary-hover-color !important;
  }
}

.signup-poll {
  margin: 10px auto 20px auto !important;
  border-radius: @default-border-radius !important;
  width: 1000px;
  height: 645px;
  .card-title {
    padding: 10px;
    margin-bottom: 20px;
    h3 {
      font-size: 28px;
      font-weight: 700;
      position: relative;
      text-align: center;
      .hand-icon-img {
        width: 55px;
        top: -110%;
        position: absolute;
      }
    }
    p {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
    }
  }
  .ant-card-body {
    height: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    flex-direction: column;
    justify-content: center;
    padding: 30px 50px;
  }
  .greeting {
    font-weight: 600;
    margin: 0 50px 10px;
  }

  .steps-shape-img {
    position: absolute;
    left: 0;
    top: 30%;
    width: 95px;
  }
  .btn-login {
    margin: 0;
  }
  .ant-form {
    margin: 0 50px;
    .form-item-textarea textarea,
    .btn-login {
      z-index: 2;
    }
    .ant-row:last-child .ant-form-item-control-input-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .btns-final-container {
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 40px auto 100px auto;
    button,
    a {
      display: flex;
      justify-content: center;
      width: 370px;
      margin-top: 20px;
    }
  }
}

.poll-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .signup-poll {
    width: 95%;
    height: 95%;
    .ant-card-body {
      padding: 30px 80px;
    }
    .steps-shape-img {
      top: 45%;
    }
    .btns-final-container {
      width: 50%;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .steps-shape-img {
      display: none;
    }
  }
}
@media screen and (max-width: 620px) {
  .signup-poll {
    margin: 20px auto !important;
    .ant-card-body {
      padding: 25px 30px;
    }
    .steps-shape-img {
      width: 80px;
      top: 55%;
    }
  }
  .last-poll-step {
    height: 400px !important;
  }
}
@media screen and (max-width: 532px) {
  .signup-poll {
    .card-title {
      h3 {
        line-height: 2rem;
        .hand-icon-img {
          width: 35px;
          top: -5%;
        }
      }
    }
    .ant-form,
    .greeting {
      margin: 0 40px;
    }
    .steps-shape-img {
      top: 73%;
    }
  }
}
@media screen and (max-width: 490px) {
  .signup-poll {
    top: 0 !important;
    .card-title {
      padding: 0 0 25px 0;
      h3 {
        font-size: 22px;
      }
      p {
        text-align: left;
      }
    }
    .card-body {
      padding: 25px;
    }
    .ant-form .ant-row:last-child .ant-form-item-control-input-content {
      flex-direction: column;
      button:last-child {
        margin-left: 0;
      }
    }
    .ant-form,
    .greeting {
      margin: 0;
    }

    .btns-final-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 40px 0 100px;
      button a {
        width: auto;
      }
    }

    .steps-shape-img {
      display: none;
    }
  }
}

@media screen and (max-width: 390px) {
  .poll-answer {
    width: 160px !important;
    font-size: 12px;
  }
  .steps-wrapper {
    margin-left: -30px !important;
    margin-right: 15px !important;
  }
  .poll-steps {
    div {
      width: 50px;
    }
  }
}
.answer-grid {
  display: grid;
  width: 100%;
  column-gap: 16px;
  row-gap: 10px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 0fr) !important;
  }
  @media screen and (max-width: 490px) {
    column-gap: 8px;
    row-gap: 8px;
  }

  .poll-answer:focus-visible {
    outline: none;
  }
  .poll-answer {
    height: 50px;
    width: 260px;
    background: #d9d9d926;
    // font-size: 14px;
    border: none;
    border-radius: 8px;
    transition: ease-in 0.3s;
    @media screen and (max-width: 490px) {
      height: 50px;
      width: 180px;
      padding: 0;
    }
  }

  .active {
    border: 1px solid @primary-color;
    background-color: white;
  }
  .active:hover {
    box-shadow: 0px 4px 8px 0px #3354ed0a;
  }

  .answer-icon {
    margin-right: 6px;
    margin-left: 0;
    span {
      height: 24px;
      width: 24px;
      font-size: 16px;
    }
  }
}

.poll-label {
  label {
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 18px !important;
  }
}

.steps-wrapper {
  display: flex;
  margin-bottom: 30px !important;
}

.poll-error {
  position: absolute;
  bottom: -40px;
  padding: 0;
  color: @error-color;
}

.last-step-text {
  margin-top: 80px;
}

.last-step-title {
  margin-top: 80px;
}


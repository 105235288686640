@import '../../../../style/variables.less';

@keyframes slide {
  0%,
  100% {
    opacity: 0;
    left: -100%;
  }
  10%,
  90% {
    opacity: 1;
    left: 0;
  }
}

.canvas {
  position: relative;
  border-radius: 4px;
  border: 1px solid rgba(217, 217, 217, 0.5);
  overflow: hidden;
  background-size: contain;
  background-color: #fff;
  position: relative;
  z-index: 1;
  min-height: 360px;
  width: max-content;
  min-width: 640px;
  margin: 0 auto;
  &-alert-message {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -100%;
    top: 2.8vh;
    opacity: 0;
    box-sizing: border-box;
    color: rgba(49, 53, 59, 1);
    padding: 0.6vw 1.35vw;
    background: rgba(239, 239, 239, 0.6);
    backdrop-filter: blur(6px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0 0.4vw 0.4vw 0;
    animation: slide 10s ease-in-out;
    .icon {
      font-size: 0.9vw;
      margin-right: 0.35vw;
    }
    .message-text {
      font-size: 0.65vw;
    }
  }
}
.working-area {
  position: relative;
  margin: 0 auto 16px;
  .video-format-limiter {
    height: 360px;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    pointer-events: none;
    z-index: 10;
    transition: width 0.2s ease-in-out;
    &.right {
      border-radius: 0 4px 4px 0;
      border-left: 0px solid #6f6d6d;
      right: 0;
    }
    &.left {
      border-radius: 4px 0 0 4px;
      border-right: 0px solid #6f6d6d;
    }
  }
  .interactivity-buttons-container {
    position: absolute;
    top: 16px;
    display: flex;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 16px;
    .btn-emulate-interactivity {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #ffffff;
      background-color: rgba(49, 53, 59, 0.85);
      border-color: rgba(49, 53, 59, 0.85);
      border-radius: @border-radius-small;
      &:hover {
        background-color: rgba(33, 36, 40, 0.85);
        border-color: rgba(33, 36, 40, 0.85);
      }
      span {
        margin: 0;
      }
      img {
        display: block;
      }
      .ant-typography {
        color: #ffffff;
        max-width: 150px;
      }
    }
  }
  .controls-emulate-question {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    gap: 16px;
    color: #ffffff;
    background-color: rgb(49, 53, 59, 0.95);
    border-color: rgb(49, 53, 59, 0.95);
    border-radius: @default-border-radius;
    padding: 8px 16px;
    p {
      margin: 0;
    }
    .answers-btns-wrapper {
      display: flex;
      gap: 8px;
      .btn-answer-number {
        color: @default-border-color;
        border-color: @default-border-color;
        &:hover {
          color: #ffffff;
          border-color: #ffffff;
        }
        &.active {
          color: @dark-color;
          background-color: #ffffff;
          border-color: #ffffff;
          box-shadow: 4px 4px 20px 0px rgba(49, 53, 59, 0.2);
        }
      }
    }
    .btn-send:hover {
      background-color: @primary-color-lighter;
      border-color: @primary-color-lighter;
    }
  }
  .voice {
    .btn-select {
      &-voice,
      &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .btn-select-voice {
      border: none;
      box-shadow: 0px 4px 8px rgba(49, 53, 59, 0.05);
      border-radius: 8px;
      height: 40px;
      padding: 4px 16px;
      width: 160px;
      &:active,
      &:focus {
        border-color: @default-border-color;
      }
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .icon-arrows {
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      .anticon {
        color: #666666;
      }
    }
    .ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
      padding: 8px 0 10px;
    }
  }

  .slide-controls {
    .player-controls {
      background: #fff;
      margin: 0 !important;
      padding: 8px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      > * {
        max-width: 640px;
        margin: 0 auto !important;
      }

      .btn-toggle-animations-mode.ant-tooltip-disabled-compatible-wrapper > button {
        opacity: 0.5;
      }

      button.btn-toggle-animations-mode,
      .btn-toggle-animations-mode > button {
        display: flex !important;
        width: 24px;
        height: 24px;
        margin: 2px 12px 0 0;
        align-items: center;
        justify-content: center;
      }

      .ant-slider-rail {
        --rail-after-width: 0px;
        --rail-after-left: 0px;
        --rail-after-border-radius-start: 0px;
        --rail-after-border-radius-end: 0px;
      }

      .ant-slider-rail::after {
        display: block;
        position: absolute;
        content: '';
        height: 8px;
        width: var(--rail-after-width);
        left: var(--rail-after-left);
        background-color: #d9d9d9;
        border-top-left-radius: var(--rail-after-border-radius-start);
        border-bottom-left-radius: var(--rail-after-border-radius-start);
        border-top-right-radius: var(--rail-after-border-radius-end);
        border-bottom-right-radius: var(--rail-after-border-radius-end);
        transition: all 0.3s ease-in-out;
      }

      .ant-slider-with-marks {
        margin-bottom: 11px;
      }

      .ant-slider-dot {
        width: 10px;
        height: 8px;
        border-radius: 0;
        top: 0px;
        border-width: 0px;
        background-color: rgba(189, 189, 189, 0.97);
      }
    }

    .voice-controls {
      margin-top: 28px;
      background: #fff;
      padding: 24px;
      border-radius: 12px;

      > * {
        max-width: 640px;
        margin: 0 auto !important;
      }

      .duration-info {
        display: flex;
        gap: 8px;
        color: @icon-dark-grey-color;
        font-size: 12px;
        font-weight: 400;
        padding: 2px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &-warning {
          color: @error-color;
        }
        &-title {
          display: flex;
          gap: 6px;
          align-items: center;
          .icon > svg {
            width: 13px;
            height: 13px;
          }
        }
      }

      .ant-segmented-item {
        &.ant-segmented-item-selected {
          .ant-segmented-item-label {
            font-weight: 700;
            color: #31353b;
          }
        }
        .ant-segmented-item-label {
          font-weight: 400;
          color: #979797;
        }
      }

      .ant-segmented-item-label {
        padding: 0 24px;
      }
    }

    .admin-controls {
      border-radius: 12px;
      margin: 20px 0;
      padding: 20px 0;
      background: #fff;

      > * {
        max-width: 640px;
        margin: 0 auto !important;
      }
    }
  }
}

.speech-menu {
  width: 160px;
}

.canvas-context-menu {
  width: 200px;
  animation-duration: 0s !important;
  .ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title {
    padding-inline-end: 12px;
    .icon:last-child {
      margin: 0;
    }
  }
}

.speech-menu,
.canvas-context-menu,
.canvas-context-submenu,
.canvas-layer-submenu {
  user-select: none;

  .ant-dropdown-menu-item {
    .icon {
      font-size: 18px;
    }

    .menu-item-label {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        display: block;
      }

      .ant-typography-secondary {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}

.ant-dropdown-menu-submenu.canvas-layer-submenu {
  width: 230px;
  .ant-dropdown-menu-title-content {
    width: 100%;
  }
  .ant-dropdown-menu-item {
    &:focus-visible {
      outline: none;
    }
    .label-wrapper {
      display: flex;
      align-items: center;
      .check-icon {
        color: @primary-color;
        font-size: 12px;
        &-container {
          flex: 0 0 12px;
          margin-right: 6px;
        }
      }
      .element-icon {
        font-size: 14px;
        margin-right: 6px;
      }
      .layer-title {
        display: block;
        flex: 1 1 auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 16px;
      }
      .layer-color {
        flex: 0 0 8px;
        width: 8px;
        height: 8px;
        border-radius: @border-radius-xsmall;
        border: 1px solid @icon-light-grey-color;
      }
    }
  }
}


.minutes-notification {
  display: flex;
  align-items: center;
  gap: 4px;

  &-text {
    color: rgba(70, 73, 79, 0.5);
    font-size: 8px;
    font-weight: 500;
    line-height: 14px;
  }

  .icon {
    font-size: 14px;
  }
}


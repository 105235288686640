@max-wrapper-width: 1000px;

.faq-container {
  width: 100%;
  background-color: rgba(219, 219, 219, 0.2);
  padding: 80px 0;
  .faq-content {
    max-width: @max-wrapper-width;
    margin: 0 auto;
    h2 {
      font-size: 30px;
      font-weight: 600;
      line-height: 2.5rem;
      text-align: center;
      margin-bottom: 30px;
    }
    .ant-collapse.card-collapse > .ant-collapse-item {
      & > .ant-collapse-header {
        padding: 20px 24px;
        .ant-collapse-header-text {
          font-size: 20px;
        }
      }
      .ant-collapse-content-box {
        font-size: 16px;
        padding: 0 24px 10px;
      }
    }
  }
}


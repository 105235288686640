@import '../../../../../style/variables.less';

@max-wrapper-width: 1000px;

.voice {
  &-container {
    max-width: @max-wrapper-width;
    padding: 46px;
    background-color: #ffffff;
    border-radius: @border-radius-large;
    .section-title {
      max-width: 360px;
      text-align: center;
      margin: 0 auto 40px;
    }
    .btn-buy-now {
      display: block;
      margin: 48px auto 0;
    }
  }
  &-content {
    width: 100%;
    margin-left: 0 !important;
    .aside {
      display: flex;
      flex-direction: column;
      position: relative;
      min-height: 450px;
      height: 100%;
      background-color: #31353b;
      background-image: url('../../../../../assets/images/public_custom_voice/dynamic-pattern.png');
      background-repeat: no-repeat;
      background-size: 100% 30%;
      background-position: center bottom;
      padding: 40px 20px 60px;
      border-radius: @border-radius-large;
      p {
        color: #ffffff;
        font-size: 20px;
        font-weight: 600;
        line-height: 140%;
        width: 90%;
        margin: 0 auto;
      }
      img {
        display: block;
        width: 100%;
        margin: auto auto 0;
      }
    }
    .steps {
      font-size: 40px;
      line-height: 48px;
      height: 100%;
      .cards-container {
        height: 100%;
        h3 {
          font-size: 18px;
          line-height: 1.4rem;
          margin-bottom: 8px;
        }
        p {
          color: @icon-dark-grey-color;
          font-size: 14px;
          line-height: 140%;
          margin-bottom: 0;
        }
        .ant-col {
          .languages-list {
            margin-top: 16px;
            &-item {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 12px;
              padding: 0;
              margin-block-end: 10px;
            }
          }
          &.voices-audio-col {
            h3 {
              margin-bottom: 16px;
            }
            .headings-container {
              display: flex;
              gap: 24px;
              width: 87%;
              margin: 0 auto;
              h5 {
                flex: 1 1 50%;
                font-size: 12px;
                font-weight: 600;
                line-height: 14.4px;

                margin-bottom: 8px;
              }
            }
            .carousel-container {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              .ant-carousel {
                width: 87%;
              }
              .btn-carousel-arrow {
                display: flex;
                font-size: 24px;
                height: auto;
                padding: 0;
              }
              .voices-audio-container {
                display: flex;
                gap: 24px;
                .audio-wrapper {
                  flex: 1 1 50%;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;
                  h5 {
                    display: none;
                  }
                  audio {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .voice-content {
    .aside {
      min-height: 150px;
      padding: 40px 20px;
      img {
        width: 40%;
        margin-top: 24px;
      }
    }
    .steps {
      h2 {
        font-size: 26px;
        line-height: 2rem;
        margin-bottom: 20px;
      }
      .cards-container {
        .step-card {
          padding: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .voice-content {
    .aside {
      img {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .voice {
    &-container {
      padding: 30px 0;
      .section-title {
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 24px;
      }
    }
    &-content {
      .aside img {
        width: 90%;
      }
      .steps {
        .cards-container {
          .ant-col {
            &.voices-audio-col {
              h3 {
                text-align: center;
              }
              .headings-container {
                display: none;
              }
              .carousel-container {
                .ant-carousel {
                  width: 70%;
                  .voices-audio-container {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    .audio-wrapper {
                      width: 100%;
                      flex: 1 1 100%;
                      h5 {
                        display: block;
                      }
                      audio {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


@import '../../style/variables.less';

.photo-avatar-modal {
  .ant-modal-content {
    display: flex;
    align-items: center;
    min-height: 400px;
    padding: 20px 40px;

    .ant-modal-body {
      width: 100%;
    }

    h1 {
      font-size: 48px;
      text-align: center;
    }
    h3 {
      color: #777;
      font-size: 40px;
      font-weight: 500;
      text-align: center;
    }

    h2 {
      font-size: 24px;
      line-height: 120%;
      margin: 20px 0 16px;
    }

    .subheader {
      line-height: 140%;
      margin-bottom: 24px;
    }

    .ant-form {
      .ant-form-item-label {
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;

        .gender-label {
          display: flex;
          align-items: center;

          .icon {
            color: @icon-dark-grey-color;
            margin-left: 10px;
          }
        }
      }

      .ant-upload.ant-upload-btn {
        padding: 46px 0;

        .ant-upload-drag-icon .icon {
          font-size: 40px;
        }

        .ant-upload-text {
          color: @icon-light-grey-color;
          font-size: 14px;
          line-height: 140%;
        }
      }

      .btn-submit {
        display: block;
        margin: 10px auto 0;
      }
    }
  }
}


@import '../../../../style/variables.less';

.brandbook-wrapper {
  display: flex;
  height: 90%;
  flex-direction: column;
  margin-right: 12px;
}

.assets-tab-buttons {
  margin-bottom: 24px;
}

.brandbook-tab-header {
  margin: 0 8px 0 0;
  font-weight: bold;
}

.brandbook-header-block {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
}

.brandbook-header-change-button {
  cursor: pointer;
  color: @primary-color;
  margin-left: auto;
}

.brandbook-info-icon {
  color: @icon-dark-grey-color;
}

.brandbook-apply-button {
  display: flex;
  margin-top: auto;
  width: 98%;
}

.brandbook-font-item {
  display: flex;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  margin-bottom: 6px;
  background-color: rgba(189, 189, 189, 0.1);
}

.brandkit-back-button {
  display: flex;
  width: 60px;
  cursor: pointer;
  margin-bottom: 16px;
  justify-content: space-between;
}

.brandbook-segment {
  margin-bottom: 24px;
  position: relative;
}

.brandbook-new-color {
  height: 37px;
  width: 37px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  border: dashed 1px @default-border-color;
}

.brandbook-colors {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.brandbook-colors-row {
  display: block;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid #a8a8a8;
  padding: 1px;
  border-radius: 8px;

  &:hover {
    .color-remove {
      display: block;
    }
  }

  .color-remove {
    position: absolute;
    top: -10px;
    right: -5px;
    width: 10px;
    height: 10px;
    opacity: 0.6;
    transition: opacity 0.3s linear;
    display: none;

    &:hover {
      opacity: 1;
    }
  }
}

.brandbook-single-color {
  height: 34px !important;
  width: 34px !important;
  border-radius: 8px;
}

.colors-spin {
  display: flex;
  align-items: center;
}

.brandbook-logo-uploader {
  max-height: 150px;
}

.color-button-disabled {
  cursor: not-allowed !important;
  color: @icon-light-grey-color;
}

.brandbook-logo {
  height: 90px;
  width: 90px;

  img {
    height: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}


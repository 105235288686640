@import '../../../../style/variables.less';
@import '../../../../style/mixins.less';

.elai-academy {
  &.course {
    .academy-content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .media-max(992px, {
        flex-direction: column;
        justify-content: flex-start;
        gap: 64px;
      });

      &-wrapper {
        max-width: 1140px;
        margin: 48px auto;

        .media-max(1188px, {
          padding: 0 24px;
        });

        .media-max(576px, {
          margin: 32px auto 0;
        });
      }

      .course-menu-container {
        width: 27.36%;
        background-color: #ffffff;
        border-radius: @border-radius-middle;
        padding: 24px 16px;

        .course-menu-wrapper {
          &.mobile-menu {
            display: none;

            .media-max(992px, {
              display: block;
            });

            .ant-collapse {
              .ant-collapse-header {
                display: flex;
                align-items: center;
                padding: 0;

                .ant-collapse-arrow {
                  font-size: 24px;
                }
              }

              .ant-collapse-content-box {
                padding: 0;
              }
            }
          }

          &.desktop-menu {
            .media-max(992px, {
              display: none;
            });
          }
        }

        .course-label {
          display: block;
          color: @icon-dark-grey-color;
          font-size: 12px;
          line-height: 14.4px;
          margin-bottom: 8px;
        }

        .course-name {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 0;
        }

        .course-menu {
          border-inline-end: none;
          margin-top: 20px;

          .ant-menu-item {
            width: 100%;
            height: auto;
            padding: 16px 32px !important;
            margin-inline: 0;
            border-radius: @default-border-radius;

            .media-max(576px, {
              padding: 16px 24px !important;
            });

            &:active,
            &.ant-menu-item-selected {
              background-color: @primary-color;

              .ant-menu-title-content {
                color: #ffffff;
              }
            }

            .ant-menu-title-content {
              color: @icon-dark-grey-color;
              font-weight: 600;
              line-height: 16.8px;
              overflow: auto;
              white-space: break-spaces;
            }
          }
        }

        .empty-course-message {
          margin-top: 16px;
        }
      }

      .lesson-content-wrapper {
        width: 59.64%;

        .lesson-content {
          font-size: 16px;

          h1 {
            font-size: 2rem;
          }

          a {
            font-weight: 700;
          }

          img {
            max-width: 100%;
            max-height: 400px;
          }

          iframe {
            width: 100%;
            aspect-ratio: 16/9;
            border-radius: @border-radius-middle;
            margin-top: 16px;
            margin-bottom: 24px;
          }

          img,
          iframe {
            border-radius: @border-radius-middle;
          }
        }

        .btn-arrow {
          margin-top: 32px;
        }
      }

      .course-menu-container,
      .lesson-content-wrapper {
        .media-max(992px, {
          width: 100%;
        });
      }
    }
  }
}

.lesson-player {
  width: 100%;
  height: 400px;
  border: none;
}


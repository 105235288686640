.interactivity-modal {
  .ant-modal-content {
    padding: 0;

    .ant-modal-body {
      display: flex;

      .interactivity-modal-content {
        display: flex;
        flex-direction: column;
        padding: 48px 40px 32px;

        h3 {
          font-size: 20px;
          line-height: 120%;
          margin-bottom: 24px;
        }

        p {
          font-size: 14px;
          line-height: 140%;
        }

        .btn-ok {
          margin-top: auto;
          margin-left: auto;
        }
      }
    }
  }
}


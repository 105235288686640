@import '../../style/variables.less';

.videos-content {
  .ant-page-header {
    padding: 16px 0;
    .ant-page-header-heading-title {
      font-size: 24px;
    }
  }
  .format-radio-btns .format-radio-btn {
    height: auto;
    border-radius: 4px;
    padding: 3px;
    margin-right: 5px;
    &:not(.active) {
      background-color: transparent;
      span {
        color: @text-color;
      }
    }
    svg {
      margin-right: 0;
    }
  }
  .sorter-dropdown-title {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 12px;
    .up_icon,
    .down_icon {
      font-size: 18px;
      margin-left: 4px;
    }
    .up_icon {
      display: none;
    }
    &.ant-dropdown-open {
      .down_icon {
        display: none;
      }
      .up_icon {
        display: inline-flex;
      }
    }
  }
  .folders-row {
    & > .ant-col {
      width: 20%;
      &.create-card-col {
        flex: 0 0 40%;
      }
    }
    .create-card,
    .folder-card {
      height: 100%;
      border-radius: @default-border-radius;
      border: none;
      .ant-card-body {
        display: flex;
        align-items: center;
        padding: 24px;
        height: 100%;
      }
    }
    .create-card {
      &.dashed {
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3csvg width='99.9%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='99.9%25' height='100%25' fill='none' rx='10' ry='10' stroke='silver' stroke-width='2' stroke-dasharray='4%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 10px;
      }
      .ant-card-body {
        justify-content: space-between;
        p {
          font-size: 18px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0;
          margin: 0 auto 0 0;
        }
      }
    }
    .folder-card {
      cursor: pointer;
      transform: translate(0px, 0px);
      &-active {
        .folder-card {
          border: 2px solid @primary-color;
          .ant-card-body {
            padding: 22px;
          }
        }
      }
      .ant-card-body {
        & > .icon {
          font-size: 30px;
          margin-right: 20px;
        }
        p {
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0;
          margin: 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }
    }
    .ant-empty {
      margin: 40px auto;
      .icon {
        font-size: 100px;
        color: @icon-grey-color;
        opacity: 0.5;
      }
    }
  }
  .videos-grid {
    .ant-row {
      margin: 0 -7.5px !important;
      .video-item {
        padding: 0 15px;
        margin-bottom: 24px;
        position: relative;
        .video-item-content {
          cursor: pointer;
          transform: translate(0px, 0px);
          &.render {
            .img-wrapper {
              position: relative;
              .mask {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                background-color: rgba(49, 53, 59, 0.3);
                & > div {
                  width: 85%;
                  & > * {
                    margin: 0;
                  }
                  p {
                    color: #ffffff;
                    text-align: right;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 12px;
                    margin-bottom: -5px;
                  }
                }
              }
            }
          }
          .card-tag {
            cursor: pointer;
            left: -6px;
          }
          .btn-dropdown-menu {
            top: -4px;
            right: -4px;
          }
        }
      }
    }
  }
  .folder-card,
  .video-item {
    .btn-dropdown-menu {
      z-index: 2;
      position: absolute;
      top: -4px;
      right: 10px;
      opacity: 0;
      color: #ffffff;
      background-color: @primary-color;
      border-color: @primary-color;
    }
  }

  .ant-table-row {
    .btn-dropdown-menu {
      color: @title-dark-grey-color;
      background-color: #ffffff;
      border-color: transparent;
      opacity: 1;
    }
    &:hover {
      .btn-dropdown-menu {
        color: #ffffff;
        background-color: @primary-color;
        border-color: @primary-color;
      }
    }
  }

  .folder-card,
  .video-item,
  .ant-table-row {
    .btn-dropdown-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: @border-radius-xsmall;
      transition: opacity 0.2s ease-in-out;
      &:hover {
        background-color: @btn-primary-hover-color!important;
        border-color: @btn-primary-hover-color!important;
      }
      &.visible {
        opacity: 1 !important;
      }
    }
    &:hover {
      .btn-dropdown-menu {
        opacity: 1;
      }
    }
  }
  .folder-card .btn-dropdown-menu {
    right: -5px;
  }
  .videos-table {
    .ant-table {
      border-radius: @border-radius-large;
      overflow: hidden;
      .icon svg {
        width: 18px;
        height: 18px;
      }
      th .icon {
        width: 30px;
        height: 30px;
      }
      th.ant-table-cell::before {
        display: none;
      }
      .ant-table-thead > tr > .date-column {
        min-width: 165px;
      }
      .ant-table-thead > tr > th {
        height: 65px;
        &:last-child {
          padding-right: 15px;
        }
      }
      .ant-table-tbody > tr > td {
        padding: 6px;
        vertical-align: middle;
        &:first-child {
          padding-left: 15px;
        }
        &:last-child {
          padding-right: 15px;
        }
      }
      .ant-table-tbody > tr > td .ant-space {
        display: flex;
        align-items: center;
      }
    }
  }
  .content {
    padding: 30px;
    border-radius: @border-radius-large;
  }
}

.videos-item-popconfirm {
  .ant-popconfirm-message {
    .ant-btn {
      border-radius: 0 4px 4px 0;
    }
    .ant-popconfirm-message-icon .arrow-back {
      cursor: pointer;
      color: #000;
      font-size: 20px;
    }
    .add-folder-icon {
      cursor: pointer;
      font-size: 20px;
      color: @primary-color;
      margin-right: 20px;
    }
    .folder-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        width: 200px;
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .ant-popconfirm-description {
    margin-left: 0;
    .popconfirm-folders-list {
      .ant-list-item {
        cursor: pointer;
        width: 100%;
        border-bottom: none;
        border-radius: @default-border-radius;
        &.active {
          color: #ffffff;
          background-color: @primary-color;
        }
        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
        & > .icon {
          font-size: 20px;
        }
        .list-item-title {
          display: flex;
          width: 87%;
          .icon {
            font-size: 18px;
            margin-right: 10px;
          }
          span:last-child {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.ant-table-filter-dropdown {
  .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;

    .ant-checkbox-wrapper + span {
      display: block;
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.video-dropdown-menu {
  .ant-dropdown-menu {
    width: 200px;
    padding: 20px 4px;
    .ant-dropdown-menu-item .ant-dropdown-menu-title-content .label-with-tooltip {
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1200px) {
  .videos-content {
    .folders-row {
      & > .ant-col {
        width: 25%;
        &.create-card-col {
          flex: 0 0 50%;
        }
      }
    }
  }
  .videos-table {
    .ant-table-thead > tr > .date-column {
      min-width: 0 !important;
    }
  }
}

@media screen and (max-width: 992px) {
  .videos-content {
    .folders-row {
      & > .ant-col {
        width: 50%;
        &.create-card-col {
          flex: 0 0 50%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .videos-content {
    .folders-row {
      & > .ant-col {
        width: 50%;
        &.create-card-col {
          flex: 0 0 100%;
        }
      }
    }
    .format-radio-btns .format-radio-btn {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 576px) {
  .videos-table {
    .ant-table {
      .ant-table-tbody > tr > td:first-child > div {
        max-width: 115px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .videos-content {
    margin-top: 0;
    .ant-table {
      .ant-table-selection-column {
        display: none;
      }
      .ant-table-thead > tr > th {
        width: 100%;
      }
    }
  }
}

.custom-breadcrumbs-item {
  cursor: pointer;
  padding: 3px 4px;
  border-radius: 4px;
  margin-inline: -4px;
  transition: color 0.2s;
}

.custom-breadcrumbs-item:hover {
  background-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.88);
}


@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/Basier-Circle-regular/basiercircle-regular-webfont.eot');
  src: url('../assets/fonts/Basier-Circle-regular/basiercircle-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Basier-Circle-regular/basiercircle-regular-webfont.woff') format('woff'),
    url('../assets/fonts/Basier-Circle-regular/basiercircle-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/Basier-Circle-regular/basiercircle-regular-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/Basier-Circle-regularitalic/basiercircle-regularitalic-webfont.eot');
  src: url('../assets/fonts/Basier-Circle-regularitalic/basiercircle-regularitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Basier-Circle-regularitalic/basiercircle-regularitalic-webfont.woff') format('woff'),
    url('../assets/fonts/Basier-Circle-regularitalic/basiercircle-regularitalic-webfont.woff2') format('woff2'),
    url('../assets/fonts/Basier-Circle-regularitalic/basiercircle-regularitalic-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/Basier-Circle-medium/basiercircle-medium-webfont.eot');
  src: url('../assets/fonts/Basier-Circle-medium/basiercircle-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Basier-Circle-medium/basiercircle-medium-webfont.woff') format('woff'),
    url('../assets/fonts/Basier-Circle-medium/basiercircle-medium-webfont.woff2') format('woff2'),
    url('../assets/fonts/Basier-Circle-medium/basiercircle-medium-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/Basier-Circle-mediumitalic/basiercircle-mediumitalic-webfont.eot');
  src: url('../assets/fonts/Basier-Circle-mediumitalic/basiercircle-mediumitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Basier-Circle-mediumitalic/basiercircle-mediumitalic-webfont.woff') format('woff'),
    url('../assets/fonts/Basier-Circle-mediumitalic/basiercircle-mediumitalic-webfont.woff2') format('woff2'),
    url('../assets/fonts/Basier-Circle-mediumitalic/basiercircle-mediumitalic-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/Basier-Circle-semibold/basiercircle-semibold-webfont.eot');
  src: url('../assets/fonts/Basier-Circle-semibold/basiercircle-semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Basier-Circle-semibold/basiercircle-semibold-webfont.woff') format('woff'),
    url('../assets/fonts/Basier-Circle-semibold/basiercircle-semibold-webfont.woff2') format('woff2'),
    url('../assets/fonts/Basier-Circle-semibold/basiercircle-semibold-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/Basier-Circle-semibolditalic/basiercircle-semibolditalic-webfont.eot');
  src: url('../assets/fonts/Basier-Circle-semibolditalic/basiercircle-semibolditalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Basier-Circle-semibolditalic/basiercircle-semibolditalic-webfont.woff') format('woff'),
    url('../assets/fonts/Basier-Circle-semibolditalic/basiercircle-semibolditalic-webfont.woff2') format('woff2'),
    url('../assets/fonts/Basier-Circle-semibolditalic/basiercircle-semibolditalic-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/Basier-Circle-bold/basiercircle-bold-webfont.eot');
  src: url('../assets/fonts/Basier-Circle-bold/basiercircle-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Basier-Circle-bold/basiercircle-bold-webfont.woff') format('woff'),
    url('../assets/fonts/Basier-Circle-bold/basiercircle-bold-webfont.woff2') format('woff2'),
    url('../assets/fonts/Basier-Circle-bold/basiercircle-bold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/Basier-Circle-bolditalic/basiercircle-bolditalic-webfont.eot');
  src: url('../assets/fonts/Basier-Circle-bolditalic/basiercircle-bolditalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Basier-Circle-bolditalic/basiercircle-bolditalic-webfont.woff') format('woff'),
    url('../assets/fonts/Basier-Circle-bolditalic/basiercircle-bolditalic-webfont.woff2') format('woff2'),
    url('../assets/fonts/Basier-Circle-bolditalic/basiercircle-bolditalic-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@import './variables.less';
@import './mixins.less';

body {
  margin: 0;
  background: #f0f2f5;
}
h1 {
  font-size: 1.5rem;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0.8rem;
}
.ant-table-tbody {
  background-color: #ffffff;
}
.ant-modal-content {
  border-radius: @default-border-radius;
  overflow: auto;
}
.ant-input-affix-wrapper,
.ant-select:not(.ant-select-customize-input, .ant-select-sm) .ant-select-selector {
  border-radius: @default-border-radius;
}
.ant-btn-primary:not(:disabled, .ant-btn-dangerous) {
  &:hover {
    border-color: @btn-primary-hover-color;
    background: @btn-primary-hover-color;
  }
  &:focus {
    border-color: @primary-color;
    background: @primary-color;
  }
  &:hover,
  &:focus {
    color: #fff;
  }
}
.ant-btn-primary:not(:disabled).dark {
  background-color: @btn-primary-hover-color;
  border-color: @btn-primary-hover-color;
  &:hover {
    border-color: @primary-color;
    background: @primary-color;
  }
}
.ant-btn-primary:has(& > .icon):not(.ant-dropdown-button button:last-child),
.ant-dropdown-button:has(& > .icon) button:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-btn-sm .icon {
  font-size: 12px;
}

.btn-arrow {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 1rem;
  padding: 0 25px;
  & > .anticon + span {
    margin-left: 0;
  }
  .anticon {
    margin-left: 10px;
    font-size: 10px;
  }
  .ant-btn-loading-icon {
    .anticon-loading {
      font-size: 16px;
      margin-inline-end: 0 !important;
    }
  }
}
.btn-login {
  margin: 50px auto 0 auto;
}

.icon {
  display: inline-flex;
  &:not(.own-colors) svg {
    width: 1em;
    height: 1em;
    path,
    rect,
    circle,
    line {
      &:not([stroke]) {
        fill: currentColor;
      }
    }
    path,
    rect,
    circle,
    line {
      &[stroke] {
        stroke: currentColor;
      }
    }
  }
}

.title-info-wrapper {
  display: flex;
  align-items: center;

  .controls-title-wrapped {
    margin: 0;
  }

  .icon {
    color: @icon-dark-grey-color;
    font-size: 14px;
    margin-left: 8px;
  }
}

.ant-segmented {
  color: @icon-dark-grey-color;
  &.segmented-default {
    .ant-segmented-item {
      &.ant-segmented-item-selected {
        .ant-segmented-item-label {
          font-weight: 600;
          color: #31353b;
        }
      }
      .ant-segmented-item-label {
        font-weight: 400;
        color: #979797;
        .icon {
          margin-right: 8px;
        }
      }
    }
  }
  &.ant-segmented-block {
    .ant-segmented-item-label {
      padding: 0 5px;
    }
  }
  .ant-segmented-item {
    &-selected {
      font-weight: 700;
    }
    &-label {
      padding: 0 24px;
    }
  }
  .ant-segmented-item,
  .ant-segmented-thumb {
    border-radius: 8px;
  }
}

.ant-breadcrumb {
  ol {
    align-items: center;

    li {
      a {
        height: auto;
      }

      &:last-child {
        font-weight: 500;
      }

      &:not(:last-child) {
        display: flex;
        align-items: center;

        .ant-breadcrumb-separator {
          display: inline-flex;
          align-items: center;
          margin: 0 2px;

          .icon {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.spin-centered {
  width: 100%;
}

.scrollbar {
  & > div:first-child {
    overflow-x: hidden !important;
    height: 100%;
  }
  & > div:nth-child(2) {
    display: none;
  }
}

.turnstile-captcha {
  display: flex;
  justify-content: center;
}

.ant-layout,
.voice-modal .ant-modal-body {
  background: url('../assets/images/background.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.ant-layout.academy {
  background: @background-color-grey;
}
.layout {
  min-height: 100vh;
  padding-bottom: 128px;
}
.ant-layout .ant-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: #ffffff;
}
.header-alert {
  border-radius: 0;
  border-width: 1px 0;
  border: none;
  &.info {
    background-color: #cec3ee;
    .ant-alert-message {
      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          p {
            margin: 0;
          }
        }
      }
    }
  }
  &.ant-alert-warning {
    background-color: #ffe2a4;
  }
  .ant-alert-message {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      & > * {
        margin: 0 10px;
      }
      p {
        text-align: center;
      }
      a {
        color: @text-color;
        text-decoration: underline;
        &.disabled {
          color: @icon-light-grey-color;
        }
      }
    }
  }
}

.ant-layout-header.academy-header,
.ant-layout-header.guest-header.academy-header {
  height: auto;
  background: transparent;
  padding: 0 100px;

  .media-max(1048px, {
    padding: 0 24px;
  });

  .academy-logo {
    display: block;
    width: 165px;
    margin-top: 44px;

    .media-max(576px, {
      width: 100px;
      margin-top: 16px;
    });
  }
}

.ant-layout-footer {
  background: transparent;
}

.ant-page-header-heading-title {
  white-space: normal;
}

.ant-card {
  border-radius: 5px;
}

.logo {
  max-height: 64px;
  float: left;
  padding: 15px 0;
  margin-right: 50px;
}
.home-content,
.profile-content,
.users-content,
.avatars-content {
  padding: 40px 30px;
}
.videos-content {
  padding: 15px 25px 30px;
  margin: 0 auto;
}

.language-select {
  .ant-select-selection-item,
  .btn-select-voice .btn-select-content {
    display: flex;
    align-items: center;
  }
  .ant-select-selector:has(.ant-select-selection-item) {
    .ant-select-selection-search .ant-select-selection-search-input {
      padding-left: 30px;
    }
  }
}
.language-select-option .ant-select-item-option-content {
  display: flex;
  align-items: center;
}

.last-videos-card,
.tutorial-video-card,
.videos-grid {
  .img-wrapper {
    width: 100%;
    border-radius: @default-border-radius;
    border: 1px solid @default-border-color;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      margin: 0 auto;
      &.vertical {
        width: 32%;
      }
      &.square {
        width: 56.5%;
      }
    }
  }
  p {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 4px;
  }
}

.admin-content {
  padding: 20px 50px;
  .content {
    max-width: none;
    .statistic-row {
      background-color: rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid @light-border-color;
      border-radius: @border-radius-large @border-radius-large 0 0;
      .ant-col {
        padding: 15px 20px 0 20px;
      }
    }
  }
}

.users-content {
  .ant-table {
    border-radius: @border-radius-large;
    overflow: hidden;
  }
}

.courses-modal {
  .ant-list-item {
    cursor: pointer;
    padding: 8px 24px;
    &:hover {
      color: @primary-color;
      transition: 0.2s;
    }
  }
}

.content {
  background: #fff;
  padding: 10px;
  border-radius: @border-radius-large;
}
.layout-container,
.content {
  max-width: 1300px;
  margin: 0 auto;
}

.ant-upload.ant-upload-drag {
  padding: 0 10px;
  height: auto;
}

.ant-checkbox-group-item {
  display: flex;
}

.template-container {
  border: 1px solid @light-border-color;
  border-radius: 4px;
  overflow: hidden;
  aspect-ratio: 16/9;
  img {
    max-width: 100%;
  }
  &.vertical,
  &.square {
    img {
      display: block;
      margin: 0 auto;
      border-radius: 0;
    }
  }
  &.vertical img {
    max-width: 31.5%;
  }
  &.square img {
    max-width: 56.3%;
  }
}
.carousel-img {
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
}
.full-height {
  height: 100%;
}

.upgrade-btn {
  margin-left: 8px;
}
.upgrade-btn.btn-arrow {
  padding: 7px;
  .anticon {
    margin-top: 1px;
  }
}

.minutes-status {
  min-width: 85px;
  display: flex;
  flex-direction: column;
  .minutes-status-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    font-weight: 500;
    line-height: 1.1;
  }
}

.form-password-hint {
  line-height: 1.05rem;
  font-size: 13px;
  margin-top: 5px;
}

.signup-poll,
.upgrade-modal,
.avatar-card {
  .progress-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #f8f8f8;
    border-radius: 50%;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
    &-point {
      width: 12px;
      height: 12px;
      background-color: @primary-color;
      display: block;
      border-radius: 50%;
    }
  }
}

.avatar-card,
.signup-poll {
  .steps-wrapper {
    max-width: 500px;
    margin: 0 auto 50px auto;
    .ant-steps {
      display: flex;
      justify-content: center;
      margin-left: -32px !important;
      .ant-steps-item-container .ant-steps-item-content {
        width: auto;
      }
      .ant-steps-item-icon .ant-steps-icon {
        top: -11px;
        left: -9px;
      }
      .progress-icon-point {
        left: 4px;
      }
      .progress-icon,
      .anticon-check-circle {
        cursor: pointer;
      }
      .anticon {
        font-size: 30px;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
      }
      .wait-icon {
        width: 30px;
        height: 30px;
        background-color: #ffffff;
        display: block;
        border-radius: 50%;
        border: 2px solid @card-border-color;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.format-radio-btns {
  display: flex;
  flex-wrap: wrap;
  .format-radio-btn {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    margin-right: 16px;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 8px 30px 0px #c0c0c040;
    span {
      color: @icon-dark-grey-color;
      white-space: nowrap;
    }
    svg {
      margin-right: 10px;
    }
    &::before {
      display: none;
    }
    &.active {
      background-color: @primary-color;
      span {
        color: #ffffff;
      }
      svg path {
        fill: #ffffff;
      }
    }
  }
  .format-radio-btn:last-child {
    margin-right: 0;
  }
  .format-radio-btn span:last-child {
    display: flex;
    align-items: center;
  }

  img,
  [role='img'] {
    width: 22px;
    margin-right: 10px;
  }
  [role='img'] {
    margin-right: 6px;
    margin-left: -4px;
  }
}

.video-creation-modal,
.home-content {
  .option-cards-row {
    & > .ant-col {
      flex: 0 0 25%;
    }
  }
  &.templates-creation-modal {
    .option-cards-row {
      & > .ant-col {
        flex: 0 0 25%;
      }
    }
  }
  .option-card {
    position: relative;
    cursor: pointer;
    width: 100%;
    background-color: #ffffff;
    padding: 24px;
    border-radius: @border-radius-large;
    & > p {
      color: @icon-dark-grey-color;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
      margin: 0 0 22px;
    }
    .icon-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 72px;
      background-color: rgba(151, 151, 151, 0.1);
      border-radius: 100%;
      margin: 0 auto;
    }
    .icon svg {
      width: 33px;
      height: 33px;
      path {
        fill: @icon-dark-grey-color;
      }
    }
    &.active {
      background-color: @primary-color;
      transition: background-color 0.3s ease-in-out;
      & > p {
        color: #fff;
      }
      .icon-circle {
        background: #ffffff;
      }
      svg {
        path {
          fill: @primary-color;
        }
      }
      .option-card-hint {
        color: #fff;
        a {
          color: #fff;
        }
      }
    }
    &.disable {
      cursor: default !important;
      pointer-events: none;
    }
    &-hint {
      display: block;
      position: absolute;
      top: 45%;
      left: 0;
      opacity: 0;
      color: @icon-grey-color;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      padding: 0 24px;
      transition: opacity 0.3s;
      p {
        margin-bottom: 5px;
      }
      a {
        font-weight: 600;
      }
    }
    &:not(.all):hover {
      .icon-circle {
        opacity: 0;
        transition: opacity 0.3s;
      }
      .option-card-hint {
        opacity: 1;
      }
    }
    .card-tag {
      right: 0;
      &.beta {
        right: 42px;
      }
    }
  }
}
.card-tag {
  cursor: default;
  font-size: 10px;
  line-height: 1rem;
  padding: 0 8px;
  border-radius: 15px;
  &.positioned-absolute {
    position: absolute;
    z-index: 2;
    top: -8px;
  }
}

.create-template-option {
  color: @icon-dark-grey-color;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px dashed @icon-light-grey-color;
  border-radius: 12px;
  span {
    margin-top: 8px;
    font-size: 14px;
  }
  .icon {
    margin: 0;
    padding: 0;
    color: @icon-light-grey-color !important;
    svg {
      padding: 0;
      margin: 0;
      height: 32px;
      width: 32px;
    }
  }
}

.video-creation-modal,
.templates-creation-modal {
  .create-template-card {
    height: 100%;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23BDBDBDFF' stroke-width='2' stroke-dasharray='5%2c 8' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
    background-color: #ffffff;
    border-radius: 12px;
    border: none;
    .ant-card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0;
      .icon {
        color: @icon-light-grey-color;
        font-size: 40px;
        margin-bottom: 14px;
      }
      span {
        color: @icon-dark-grey-color;
      }
      &:hover {
        .icon,
        span {
          color: #919191;
          transition: 0.2s;
        }
      }
    }
  }
}

.ant-collapse.card-collapse {
  background-color: transparent;
  border: none;
  .ant-collapse-item {
    border-bottom: none;
    border-radius: @default-border-radius;
    margin-bottom: 5px;
    position: relative;
    box-shadow: 0px 4px 12px 0px rgba(131, 131, 131, 0.1);
    &.ant-collapse-item-active {
      .ant-collapse-header {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        &::before {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .ant-collapse-content {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .ant-collapse-header {
      align-items: center;
      z-index: 1;
      font-weight: 600;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        z-index: -1;
        border-radius: @default-border-radius;
      }
    }
    .panel-card {
      padding: 16px;
      background-color: #ffffff;
      span {
        font-weight: 600;
      }
    }
    .ant-collapse-content {
      border-top: none;
      border-radius: @default-border-radius;
      &-box {
        padding-top: 0;
      }
      ol {
        padding-left: 16px;
        margin: 0;
      }
    }
  }
}

.record-container {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 18px;
  border-radius: 80px;
  background-color: rgba(151, 151, 151, 0.1);
  .record-button {
    cursor: pointer;
    width: 48px;
    height: 48px;
  }
  .time-limit {
    color: #979797;
  }
}

.preview-main {
  &-content {
    height: calc(100vh - 65px);
  }
  &-card {
    max-width: 800px;
    margin: 20px auto 0;
    .ant-page-header-heading .ant-btn {
      &.ant-btn-compact-first-item {
        border-radius: 8px 0px 0px 8px;
        -webkit-border-radius: 8px 0px 0px 8px;
      }
      &.ant-btn-compact-last-item {
        border-radius: 0px 8px 8px 0px;
        -webkit-border-radius: 0px 8px 8px 0px;
      }
    }
  }
  &-sider.ant-layout-sider {
    background: rgba(255, 255, 255, 0.8);
    padding: 16px 16px 32px;
  }
}

.btn-play-circle {
  color: #ffffff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 1) 100%);
  border: none;
  &:not(:disabled) {
    &:hover,
    &:active {
      color: #ffffff;
    }
  }
}

.auth-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media screen and (max-width: 1360px) {
  .video-creation-modal {
    .option-cards-row {
      justify-content: center;
      .option-card {
        padding: 31px 5px;
        & > p {
          font-size: 14px;
          line-height: 16px;
          margin: 0 0 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 1120px) {
  .home-content {
    .option-cards-row {
      justify-content: center;
      & > .ant-col {
        flex: 0 0 30%;
      }
    }
  }
}

@media screen and (max-width: 992px), (max-height: 750px) {
  .video-creation-modal {
    .option-cards-row {
      justify-content: center;
      & > .ant-col {
        flex: 0 0 20%;
      }
    }
    &.templates-creation-modal {
      .option-cards-row {
        & > .ant-col {
          flex: 0 0 20%;
        }
      }
    }
  }
  .video-creation-modal,
  .video-creation-modal.templates-creation-modal {
    .option-card {
      display: flex;
      flex-direction: column;
      padding: 16px;
      & > p {
        font-size: 14px;
        line-height: 16px;
        margin: 0 0 12px;
      }
      &-hint {
        top: 35%;
        box-sizing: border-box;
        font-size: 12px;
        line-height: 14px;
        width: 100%;
        padding: 10px;
      }
    }
  }
}

@media screen and (max-width: 770px) {
  .only-desktop {
    display: none;
  }
  .header-alert {
    .ant-alert-message {
      div {
        font-size: 12px;
        & > p {
          margin: 0;
        }
      }
    }
  }
  .format-radio-btns {
    width: 100%;
    .format-radio-btn {
      margin: 0 0 10px 10px;
      padding: 5px 10px;
    }
  }
  .video-creation-modal,
  .home-content {
    .option-cards-row {
      & > .ant-col {
        flex: 0 0 30%;
      }
    }
  }
  .ant-layout.preview-main-layout {
    flex-direction: column;
    min-height: calc(100vh - 208px);
    .preview-main-content,
    .preview-main-sider {
      width: 100% !important;
      max-width: 100% !important;
    }
    .preview-main-content {
      flex: 0 0 auto !important;
      height: auto;
    }
    .preview-main-sider {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto !important;
      min-height: 480px;
      margin-top: 16px;
      .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        .comments {
          flex: 1 1 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .home-content,
  .profile-content,
  .users-content,
  .videos-content {
    padding: 10px 25px 25px;
  }
  .home-content {
    .option-card {
      & > p {
        font-size: 18px;
      }
      &-hint {
        top: 45%;
      }
      .icon-circle {
        width: 80px;
        height: 80px;
      }
      .icon svg {
        width: 35px;
        height: 35px;
      }
    }
  }
  .video-creation-modal {
    &.templates-creation-modal {
      .option-cards-row {
        & > .ant-col {
          flex: 0 0 50%;
        }
      }
    }
    .format-radio-btns .format-radio-btn svg {
      margin-right: 5px;
    }
    .option-cards-row {
      justify-content: start;
      margin-top: 10px;
      & > .ant-col {
        flex: 0 0 50%;
        .option-card {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 14px 8px;
          border-radius: @border-radius-middle;
          & > p {
            font-size: 16px;
            font-weight: 500;
            margin: 0;
          }
          .icon-circle,
          &-hint {
            display: none;
          }
        }
      }
    }
  }
  .video-creation-modal,
  .templates-modal {
    .create-template-card {
      .ant-card-body {
        .icon {
          font-size: 20px;
          margin-bottom: 8px;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
  .signup-poll,
  .avatar-card {
    .steps-wrapper {
      margin: 0 0 30px 0;
    }
  }
  .ant-page-header-heading {
    flex-direction: column;
  }
}

@media screen and (max-width: 420px) {
  .ant-layout-header {
    justify-content: flex-start;
    .logo {
      width: 60px;
      margin-right: 5px;
    }
    .upgrade-btn {
      margin: 0 0 0 40px;
      span {
        margin: 0;
      }
      .anticon {
        display: none;
      }
    }
  }
  .header-alert {
    &.info {
      .ant-alert-message {
        .upgrade-btn {
          margin-top: 8px;
        }
      }
    }
  }
  .main-content-container {
    padding-bottom: 80px !important;
  }
  .header-menu-wrapper {
    display: flex;
    align-items: center;
  }
  .header-menu {
    border-bottom: none;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) {
    & > .ant-menu-item,
    & > .ant-menu-submenu {
      padding: 0 8px;
    }
  }
  .videos-content {
    margin-top: 0px;
    .ant-table {
      .ant-table-selection-column {
        display: none;
      }
      .ant-table-thead > tr > th {
        width: 100%;
      }
    }
  }
}

.dark-popconfirm {
  --antd-arrow-background-color: #31353bf2;
  color: #ffffff;
  .ant-popover-inner {
    background: #31353bf2;
    border-radius: 8px;
    .ant-popconfirm-message {
      display: flex;
      align-items: flex-start;
      color: #ffffff;
    }
    .ant-btn-default:disabled {
      color: @default-border-color;
    }
  }
  .ant-popconfirm-inner-content {
    .ant-btn-primary:hover {
      background-color: #3a57e2;
      border-color: transparent;
    }
  }
}


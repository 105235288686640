@import '../../../../../style/variables.less';

.shortcut-popover {
  width: 352px;

  .ant-popover-inner {
    padding: 0;
    border-radius: @border-radius-middle;

    .popover-content-wrapper {
      padding: 8px 8px 24px;

      &.windows .shortcuts-section .shortcuts-wrapper .shortcut-item .key-wrapper.full-width {
        width: 64px;
      }

      &.mac .shortcuts-section .shortcuts-wrapper .shortcut-item .key-wrapper.full-width {
        width: 52px;
      }

      .shortcut-popover-title {
        display: flex;
        justify-content: space-between;
        padding-left: 8px;

        h3 {
          font-size: 16px;
          line-height: 19.2px;
          font-weight: 700;
          margin: 12px 0 16px;
        }

        .btn-close {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          padding: 0;

          .icon {
            font-size: 16px;
          }
        }
      }

      .shortcuts-section {
        padding: 0 8px;

        & ~ .shortcuts-section {
          margin-top: 12px;
        }

        h4 {
          font-size: 14px;
          line-height: 16.8px;
          font-weight: 700;
          margin-bottom: 12px;
        }

        .shortcuts-wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .shortcut-item {
            .title-text {
              margin-right: auto;
            }

            .key-wrapper {
              color: @text-color;
              background-color: rgba(194, 210, 255, 0.4);
            }
          }
        }
      }
    }
  }
}


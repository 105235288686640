@import url('../../../../../style/variables.less');

.contact-form {
  max-width: 700px;
  background-color: #fff;
  border-radius: @border-radius-large;
  padding: 40px 0;
  &.tabs-content-wrapper {
    margin-bottom: 40px;
  }
  .form-container {
    margin: 0 auto;
    h2 {
      font-size: 30px;
      font-weight: 700;
      line-height: 2.5rem;
      text-align: center;
      width: 80%;
      margin: 0 auto 30px;
    }
    .ant-form {
      width: 55%;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-form .form-container {
    h2 {
      font-size: 28px;
      line-height: 33.6px;
      text-align: left;
      margin-bottom: 30px;
    }
    .ant-form {
      .ant-form-item:nth-child(3) {
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .contact-form {
    .form-container {
      h2 {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 16px;
      }
      .ant-form {
        width: 80%;
      }
    }
  }
}


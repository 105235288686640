@import '../../../../style/variables.less';
@import '../../../../style/mixins.less';

.onboarding-modal {
  width: 1080px !important;

  .media-max-height(850px, {
    width: 800px !important;
  });

  .ant-modal-content {
    padding: 24px 24px 33px;

    .ant-modal-close {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 24px;
      inset-inline-end: 24px;

      .ant-modal-close-x {
        color: @text-color;
        font-size: 24px;
      }
    }

    .ant-modal-body {
      margin-top: 32px;

      .onboarding-modal-title {
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 0;
      }

      .onboarding-modal-subtitle {
        color: #31353b;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        max-width: 580px;
        margin: 24px auto 32px;
      }

      .onboarding-image-container {
        display: flex;
        position: relative;

        .btn-play-circle {
          cursor: pointer;
          position: absolute;
          bottom: 130px;
          left: 50%;
          transform: translateX(-50%);
          width: 72px;
          height: 72px;
          pointer-events: all;

          .media-max-height(850px, {
            bottom: 100px;
            width: 64px;
            height: 64px;
          });

          .icon {
            font-size: 28px;
          }
        }
      }

      .onboarding-video,
      .onboarding-image {
        width: 100%;

        &-container.hidden {
          display: none;
        }
      }

      .onboarding-video {
        border-radius: 16px;
      }
    }

    .ant-modal-footer {
      margin-top: 28px;

      .btn-skip {
        display: inline-flex;
        align-items: center;
        color: @icon-dark-grey-color;
        line-height: normal;
        padding: 0;

        .icon {
          font-size: 16px;
        }
      }
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-alert {
      max-width: 55%;
      display: flex;
      align-items: center;
      .media-max(1440px, {
        max-width: 70%;
      });
      .ant-alert-description {
        text-align: start;
        .media-max(1440px, {
          font-size: 12px;
        });
      }
    }
  }
}


@import '../../style/variables.less';

@keyframes float {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(15%);
  }

  100% {
    transform: translateY(0%);
  }
}

.video-story {
  height: 100vh;
  min-height: 730px;
  background-color: #ffffff;

  .video-header {
    .video-name {
      overflow: visible;
      width: 100%;

      &-wrapper {
        width: 55%;
        overflow: visible;
      }

      &-input {
        max-width: 400px;
      }

      .btn-comments {
        display: flex;
        align-items: center;
        gap: 16px;
        color: #ffffff;
        font-weight: 600;
        margin-left: 16px;

        &:hover {
          background-color: transparent;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 32px;
          background-color: rgba(189, 189, 189, 0.2);
        }

        .ant-badge {
          display: inline-flex;
          color: inherit;

          .ant-badge-count {
            box-shadow: none;
          }
        }

        .icon {
          font-size: 18px;
        }
      }
    }

    .header-extra {
      .menu-icon {
        display: none;
      }

      .save-icon-wrapper {
        margin-right: 20px;
      }

      .btn-template[disabled] > * {
        color: #ffffff;
      }
    }
  }

  .header-menu-mobile {
    display: none;
  }

  .video-story-container {
    display: flex;
    flex-direction: row;

    .video-story-sider {
      width: 368px !important;
      flex: 0 0 368px !important;
      max-width: 368px !important;
      min-width: 368px !important;
      background-color: @default-border-color-30;
      padding: 40px 32px;

      .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .description-item {
          margin-bottom: 24px;

          h5 {
            font-size: 16px;
            line-height: 19.2px;
          }

          .ant-typography-secondary {
            display: block;
            font-size: 14px;
            line-height: 19.6px;
            margin-bottom: 5px;
          }
        }

        .sidebar-collapse {
          .ant-collapse-header {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            border-radius: @default-border-radius;

            &:hover {
              background-color: rgba(189, 189, 189, 0.3);
            }

            .ant-collapse-header-text {
              font-size: 16px;
              font-weight: 700;
              line-height: 19.2px;
            }
          }

          .ant-collapse-content-box {
            padding: 8px 16px;

            .ant-typography-secondary {
              display: block;
              font-size: 14px;
              line-height: 19.6px;
              margin-bottom: 5px;
            }
          }
        }

        .tutorial-card {
          border-radius: @border-radius-middle;
          margin: 16px 0 37px 20px;

          .ant-card-body {
            padding: 32px 24px;
            border-radius: @border-radius-middle;

            @media screen and (max-height: 768px) {
              padding: 24px;
            }

            h4 {
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 16px;
            }

            .ant-typography-secondary {
              display: block;
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 24px;
            }

            .video-container {
              position: relative;
              width: 100%;
              overflow: hidden;
              padding-top: 56.25%;
              margin-top: auto;
              border-radius: @default-border-radius;

              .youtube-video {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                border: none;
              }
            }
          }
        }
      }
    }

    .video-story-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #ffffff;
      padding: 56px;
      height: 100%;

      .input-gpt {
        position: relative;
        width: 75%;
        max-width: 655px;
        margin: 30px auto 0;

        &.disabled {

          .ant-input,
          .magic-wand-icon,
          .submit-icon {
            pointer-events: none;
            opacity: 0.5;
          }
        }

        .magic-wand-icon {
          position: absolute;
          top: 13px;
          left: 24px;
          font-size: 22px;
          color: @icon-dark-grey-color;
        }

        .submit-icon {
          cursor: pointer !important;
          position: absolute;
          top: 13px;
          right: 24px;
          font-size: 22px;
          color: @primary-color;

          &.disabled {
            color: @icon-dark-grey-color;
            pointer-events: none;
          }
        }

        .ant-input {
          padding: 12px 60px 12px 60px;
          border-radius: @default-border-radius;
          border: 1px solid rgba(217, 217, 217, 0.2);
          box-shadow: 0px 4px 16px 0px rgba(49, 53, 59, 0.05);
        }

        .loading-notification {
          display: flex;
          align-items: center;
          position: absolute;
          top: -115%;
          right: 0;
          background-color: #f8f8f8;
          padding: 8px 12px;
          border-radius: @default-border-radius;
          animation: float 1.5s infinite;

          .icon {
            color: @primary-color;
            font-size: 24px;
            margin-right: 12px;
          }
        }
      }

      .text-editor-scrollbar {
        position: relative;

        .text-editor {
          outline: none;
          max-width: 790px;
          margin: 0 auto;

          .slide-item {
            width: 100%;
            background-color: #ffffff;
            border-radius: @border-radius-middle;
            padding: 14px;
            margin: 0 !important;

            &.error .placeholder {
              color: @error-color;
            }

            &-thumbnail {
              cursor: pointer;
              display: flex;
              align-items: flex-start;
              padding-right: 12px;
              &.disabled {
                cursor: not-allowed !important;
              }
            }

            &-index {
              align-self: flex-start;
              color: @icon-dark-grey-color;
              font-size: 12px;
              font-weight: 700;
              line-height: 12px;
              margin-right: 4px;
            }

            &-image {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 132px;
              height: 80px;
              border-radius: 4px;
              border: 1px solid @default-border-color;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              overflow: hidden;

              &.active,
              &:hover {
                .item-image-mask {
                  opacity: 1;
                }
              }

              .item-image-mask {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background-color: rgba(12, 13, 13, 0.6);
                transition: opacity 0.3s;

                .icon {
                  color: #ffffff;
                  font-size: 24px;
                }
              }

              .video-format-limiter {
                position: absolute;
                top: 0;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.7);
                transition: width 0.2s ease-in-out;

                &.left {
                  left: 0;
                }

                &.right {
                  right: 0;
                }
              }
            }

            .template-column {
              display: flex;
              align-items: flex-start;
              width: 24%;

              .info-icon {
                cursor: pointer !important;
                font-size: 16px;
                color: @icon-dark-grey-color;
                margin-right: 12px;
              }
            }

            .options-column {
              justify-content: flex-end;
              width: 5%;

              .btn-slide-option {
                color: @icon-dark-grey-color;
                background-color: rgba(189, 189, 189, 0.2);
                box-shadow: 0px 0px 4px 0px #38333326;
                border: none;

                &:hover:not(:disabled):not(.ant-dropdown-open) {
                  color: @title-dark-grey-color;
                  transition: 0.2s;
                }

                &.ant-dropdown-open {
                  color: #ffffff;
                  background-color: @primary-color;
                  transition: 0.2s;
                }

                &.delete {
                  &:hover:not(:disabled) {
                    color: @error-color;
                    background-color: @error-light-color;
                    transition: 0.2s;
                  }
                }
              }

              .loader {
                font-size: 24px;
                color: @icon-dark-grey-color;
              }

              .btn-slide-option,
              .loader {
                margin-bottom: 8px;
              }
              .duration-info {
                font-size: 12px;
                color: @icon-dark-grey-color;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                &-title {
                  display: flex;
                  gap: 2px;
                }
                &-warning {
                  color: @error-color;
                }
              }
            }

            .content-column {
              width: 71%;

              ul {
                outline: none;
              }

              .text-editor-notes {
                font-size: 12px;
                font-weight: 400;
                color: #979797;
                margin-bottom: 12px;

                &.speech-notes {
                  margin-top: 24px;
                }

                .icon {
                  margin-left: 4px;
                }
              }

              .text-editor-complex-warning {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 8px 16px;
                margin-right: 8px;
                gap: 8px;

                background: rgba(189, 189, 189, 0.1);
                border-radius: 8px;

                font-weight: 400;
                font-size: 12px;
                color: #31353B;

                .icon {
                  color: #FFAF36;
                }
              }

              .text-editor-slide {
                h2 {
                  font-size: 22px;

                  &~.text-editor-btns {
                    display: flex;
                    align-items: center;
                    margin-top: 16px;
                  }
                }

                .text-editor-btns {
                  display: flex;
                  align-items: center;
                  margin-bottom: 16px;

                  .btn-add-item {
                    display: block;
                    color: @primary-color;
                    background-color: transparent;
                    margin-right: 10px;
                  }
                }

                .text-editor-subheader {
                  font-size: 18px;
                  line-height: 16.8px;
                  outline: none;
                  font-weight: 600;
                  color: rgba(0, 0, 0, 0.5);
                }

                .text-editor-list {
                  li {
                    margin-bottom: 10px;
                  }
                }

                .text-editor-speech {
                  font-size: 16px;
                  line-height: 19.6px;
                }

                .text-editor-image {
                  cursor: pointer;
                  overflow: hidden;
                  border: 1px solid @default-border-color;
                  border-radius: 4px;
                  margin-right: 8px;

                  .image-thumbnail {
                    object-fit: cover;
                  }
                }

                .text-editor-image,
                .image-thumbnail {
                  width: 50px;
                  height: 50px;
                }

                .ant-typography-secondary {
                  line-height: 16.8px;
                }

                .text-editor-speech,
                h2,
                .ant-typography-secondary {
                  outline: none;
                }
              }

              .ant-divider {
                margin: 24px 0 0 0;

                &-with-text {

                  &::after,
                  &::before {
                    border-block-start-color: rgba(189, 189, 189, 0.1);
                  }
                }

                &-inner-text {
                  padding: 0;
                }
              }

              .btn-add-slide {
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                  font-size: 10px;
                }
              }
            }
          }

          .unselectable {
            -moz-user-select: -moz-none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -o-user-select: none;
            user-select: none;
          }

          [contenteditable='true']:not(ul):after {
            content: '.';
            visibility: hidden;
          }
        }
      }
    }
  }
}

.outline-modal {
  .list-input-label {
    margin-bottom: 0;
  }

  .scrollbar {
    margin: 8px 0;
    border: 1px solid @default-border-color;
    border-radius: @default-border-radius;

    .list-input {
      outline: none;
      box-sizing: border-box;
      min-height: 298px;
      padding: 16px 10px 16px 30px;
      margin: 0 0 2px 0;
    }
  }

  .ant-modal-footer {
    margin-top: 27px;
  }
}

.slide-count-modal {
  h2 {
    padding-right: 25px;
  }

  .slider-container {
    padding: 24px 32px;
    background: rgba(245, 248, 253, 0.5);
    border-radius: @default-border-radius;
    margin-top: 40px;

    p {
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
}

.slide-count-modal,
.outline-modal,
.validation-modal {
  h2 {
    font-size: 20px;
    line-height: 24px;
    padding-right: 35px;
    margin-top: 10px;
  }
}

.image-modal {
  // fix for nested "Generate with AI" Drawer
  .ant-modal-content {
    overflow-x: hidden;
    .ant-modal-body {
      position: relative;
    }
  }

  .ant-modal-content {
    padding-bottom: 0;

    h2 {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 24px;
    }

    .ant-segmented {
      margin-bottom: 16px;
    }

    .uploads-scrollbar,
    .pexels-scrollbar {
      min-height: 310px !important;
      max-height: 310px !important;

      &>div:first-child {
        min-height: 310px !important;
        max-height: 310px !important;
      }
    }

    .uploads-scrollbar {

      .ant-upload-list-item-container,
      .ant-upload-select {
        width: 99px !important;
        height: 99px !important;
      }
    }
  }
}

.video-story,
.outline-modal {
  .placeholder {
    color: #aaaaaa;
  }
}

.layouts-popover {
  .ant-popover-inner {
    padding: 0;
    border-radius: @border-radius-large;

    .layouts-popover-content {
      min-width: 320px;
      padding: 16px 16px 24px 24px;

      .visible {
        display: block;
      }

      .hidden {
        display: none;
      }

      .popover-title-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        .popover-title {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 16px;
          margin-top: 9.5px;

          h4 {
            line-height: 19.2px;
            font-weight: 700;
            margin-bottom: 0;
          }

          .icon {
            color: @icon-dark-grey-color;
          }
        }

        .title-btn {
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          border-radius: @border-radius-xsmall;

          &.visible,
          &.close {
            display: flex;
          }

          .icon {
            font-size: 14px;
          }
        }
      }

      .layouts-scrollbar {
        height: 317px !important;

        &>div:first-child {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .slide-item-image {
            cursor: pointer;
            height: 72px;
          }

          .btn-show-more {
            margin-top: auto;
          }
        }
      }

      .confirmation-content {
        .slide-item-image.layout-image {
          width: 100%;
          height: 138px;
          border-radius: @default-border-radius;
          margin-bottom: 24px;
        }

        .warning-message {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          margin-bottom: 16px;

          .icon {
            color: @warning-color;
          }

          .warning-message-text {
            font-size: 12px;
            font-weight: 400;
            line-height: 16.8px;
            color: @icon-dark-grey-color;
            margin-bottom: 0;
          }
        }

        .confirmation-question-text {
          font-weight: 600;
          line-height: 19.6px;
          margin-bottom: 16px;
        }

        .confirmation-buttons-wrapper {
          display: flex;
          justify-content: flex-end;
          gap: 12px;
        }
      }

      .layouts-scrollbar>div:first-child,
      .confirmation-content {
        padding-right: 8px;
      }
    }
  }
}

.input-gpt-dropdown {
  background-color: #ffffff;
  width: 280px;
  min-width: auto !important;

  .ant-dropdown-menu .ant-dropdown-menu-item .menu-item-label .icon {
    color: #879cff;
  }
}

.input-gpt-dropdown,
.slide-enhancements-dropdown {
  .ant-dropdown-menu {
    padding: 16px 6px;

    .ant-dropdown-menu-item {
      padding: 0;

      .menu-item-label {
        display: flex;
        align-items: center;
        padding: 8px 12px;
      }
    }
  }
}

.slide-enhancements-dropdown {
  min-width: 200px !important;
}

.templates-modal {
  .format-radio-btns {
    margin-bottom: 20px;
  }

  .template-media {
    width: 100%;
    display: block;
    box-sizing: border-box;
    border: 1px solid @light-border-color;
    border-radius: 12px;

    &.vertical {
      border-radius: 8px;
    }
  }

  .template-card {
    cursor: pointer;
    flex: 1;
    min-height: 50px;
    max-height: 200px;
    max-width: 100%;
    overflow: hidden;
    border-radius: 12px;

    &.vertical,
    &.square {
      img {
        display: block;
        margin: 0 auto;
      }
    }

    &.vertical img {
      max-width: 31.5%;
    }

    &.square img {
      max-width: 56.3%;
    }
  }
}

.validation-modal {
  .description {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  .error-alert {
    display: flex;
    align-items: flex-start;
    background-color: rgba(189, 189, 189, 0.1);
    padding: 16px 12px;
    border-radius: @default-border-radius;

    .icon {
      color: @error-color;
      font-size: 24px;
      margin-right: 12px;
    }

    .error-message {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-weight: 700;
      }
    }
  }

  .ant-modal-footer {
    margin-top: 42px;
  }
}

.info-popover {
  width: 256px;

  .ant-popover {
    &-inner {
      background: rgba(0, 0, 0, 0.85);
      padding: 20px 16px;

      &-content {
        color: #ffffff;

        h4 {
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          margin-bottom: 12px;
        }

        .instruction-list {
          padding-left: 20px;
          margin: 0 0 16px 0;

          li {
            line-height: 14px;
            margin-bottom: 5px;

            span {
              &:first-child {
                font-size: 14px;
                font-weight: 700;
                line-height: 17px;
              }

              &:last-child {
                font-size: 12px;
                font-weight: 400;
              }
            }
          }
        }

        .slide-scheme {
          display: flex;
          align-items: flex-start;
          padding-left: 20px;

          .scheme-editing-content {
            flex: 1;
            padding-right: 10px;
            margin-left: 10px;

            .scheme-item {

              &-visuals,
              &-voiceover {
                width: 100%;
              }
            }
          }

          .scheme-item {
            display: flex;

            &-visuals {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 8px;
              margin-bottom: 10px;

              .heading {
                display: block;
                font-size: 14px;
                font-weight: 700;
                line-height: 14px;
              }

              .subheader {
                display: block;
                font-size: 12px;
                line-height: 12px;
                margin-top: 6px;
              }

              .icons-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;

                .icon {
                  color: #ffffff;
                  font-size: 14px;

                  &+.icon {
                    margin-top: 6px;
                  }
                }
              }
            }

            &-voiceover {
              font-size: 14px;
              line-height: 14px;
              padding: 4px 8px;
              margin: 0;
            }

            &-visuals,
            &-voiceover {
              border: 1px dashed #ffffff;
              border-radius: 3px;
            }

            &>span {
              line-height: 1rem;
              margin-right: 4px;
            }
          }
        }
      }
    }

    &-arrow:before {
      background: rgba(0, 0, 0, 0.85);
    }
  }
}

.comments-popover {
  top: 70px !important;
  width: 352px;

  .ant-popover-content {
    .ant-popover-inner {
      padding: 16px;
      border-radius: @border-radius-middle;

      .ant-popover-inner-content {
        min-height: 600px;
        height: 50vh;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .video-story {
    .video-story-container .video-story-sider {
      display: none;
    }
  }

  .video-header .video-name .btn-comments &>span:last-child {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .video-story .video-header .video-name-wrapper {
    max-width: 40%;
  }
}

@media screen and (max-width: 900px) {
  .video-story .video-story-container .video-story-content .text-editor-scrollbar .text-editor .slide-item {
    .template-column {
      width: 100%;
      justify-content: flex-start;
    }

    .content-column {
      width: 90%;
    }
  }
}

@media screen and (max-width: 720px) {
  .video-story {
    .video-header {
      flex-direction: column;
      align-items: flex-start;

      .video-name-wrapper {
        max-width: 100%;
        width: 100%;

        .video-name {
          width: 100%;
          margin-bottom: 16px;
        }
      }
    }

    .video-story-container .video-story-content .input-gpt {
      width: 100%;
    }
  }
}

@media screen and (max-width: 576px) {
  .video-story {
    height: auto;
    min-height: auto;

    .video-header {
      flex-direction: row;
      align-items: center;
      padding: 12px 24px;

      .video-name-wrapper {
        width: 80%;

        .video-name {
          margin-bottom: 0;
        }
      }

      .header-extra {
        min-width: auto;
        margin-right: -18px;

        .btn-template,
        .btn-render {
          display: none !important;
        }

        .save-icon-wrapper {
          margin-right: 0;
        }

        .menu-icon {
          display: block;
          font-size: 22px;
          color: #ffffff;
          margin-right: 8px;
          transform: rotate(90deg);
        }
      }
    }

    .header-menu-mobile {
      display: block;
      max-height: 0px;
      margin-top: -2px;
      overflow-y: hidden;
      transition: max-height 0.2s linear;

      &.open {
        max-height: 152px;
      }

      .ant-menu {
        background-color: @btn-primary-hover-color;
        padding: 30px 8px;

        .ant-menu-item {
          display: flex;
          align-items: center;
          color: #ffffff;

          .icon {
            font-size: 24px;
          }

          .ant-menu-title-content {
            font-size: 16px;
            font-weight: 700;
            margin-inline-start: 15px;
          }
        }
      }
    }

    .video-story-container .video-story-content {
      padding: 24px 24px 0 24px;
      justify-content: flex-start;

      .text-editor-scrollbar {
        .title {
          font-size: 32px;

          line-height: 40px;
        }

        .subtitle {
          line-height: 18px;
          width: 90%;
        }
      }

      .input-gpt {
        margin: 10px auto;
      }
    }
  }
}

@media screen and (max-height: 968px) {
  .video-story .video-story-container .video-story-content .text-editor-scrollbar .text-editor .slide-item .content-column .text-editor-slide {
    h2 {
      font-size: 20px;
    }

    .text-editor-subheader {
      font-size: 14px;
    }

    .text-editor-speech {
      font-size: 14px;
    }
  }
}


.brand-kit-modal-wrapper {
  margin-bottom: 16px;
}

.brand-kit-title {
  display: flex;
}

.brand-kit-text {
  margin-right: 10px;
}

.brandkit-modal-content {
  display: flex;
  margin-top: 10px;
  position: relative;
  max-width: 140px;
  height: 38px;
  padding-left: 7px;
  padding-right: 2px;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;

  .brandbook-single-color {
    width: 12px;
    height: 12px;
    margin-right: 3px;
    border-radius: 50%;
    border: 1px solid @default-border-color;
  }

  .disabled {
    cursor: 'not-allowed';
  }
}

.brand-kit-checked {
  position: absolute;
  top: -5px;
  right: -8px;
  background-color: @primary-color;
  border-radius: 50%;
  color: white;
}

.add-brand-kit {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: @title-dark-grey-color;
}

.brandkit-color {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  border-radius: 50%;
  border: 1px solid @icon-grey-color;
}


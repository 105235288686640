@import '../../style/variables.less';

.home-content {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  & > h2 {
    text-align: center;
    font-size: 24px;
    line-height: 28.8px;
    width: 43%;
    margin: 0 auto 35px;
  }
  .option-card {
    height: 100%;
  }
  .ant-card {
    border: none;
    border-radius: @border-radius-large;
    .ant-card-body {
      display: flex;
      flex-direction: column;
      min-height: 346px;
      h4 {
        font-size: 18px;
        line-height: 19.8px;
        margin-bottom: 24px;
      }
      a {
        font-size: 16px;
      }
    }
  }
  .photo-avatars-card {
    .title-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      h4 {
        margin: 0 10% 0 0;
      }
    }
    .img-wrapper img {
      cursor: pointer;
      width: 100%;
      border-radius: 8px;
    }
    .photo-avatar-add {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      aspect-ratio: 1/1;
      background: rgba(217, 217, 217, 0.1);
      padding: 8px;
      border-radius: @default-border-radius;
      border: 1px dashed @default-border-color;
      .portrait-icon {
        color: @primary-color;
        width: 66%;
        height: 66%;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .plus-icon {
        align-self: flex-end;
        color: @dark-color;
        width: 22%;
        height: 22%;
      }
    }
  }
  .last-videos-card .last-videos-scrollbar,
  .tutorial-scrollbar,
  .photo-avatars-card .photo-avatars-scrollbar {
    height: 212px !important;
  }
  .custom-avatar-card {
    img {
      display: block;
      width: 62%;
      margin: auto;
    }
  }
  .blank-video-card {
    cursor: pointer;

    .ant-card-body {
      text-align: center;
      display: block;
      h4 {
        margin-bottom: auto;
      }
      img {
        max-height: 240px;
        max-width: 100%;
      }
      button {
        margin-top: auto;
      }
    }
  }
  .last-videos-card,
  .photo-avatars-card,
  .custom-avatar-card {
    .ant-card-body > a {
      display: block;
      align-self: flex-end;
      font-weight: 600;
      margin-top: 16px;
    }
  }
}

.link {
  color: @primary-color;
  font-weight: 600;
  font-size: 17px;
}

@media screen and (max-width: 1360px) {
  .home-content {
    .blank-video-card{
      .ant-card-body{
        img {
          width: 100%;
          max-height: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home-content > h2 {
    width: 90%;
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 576px) {
  .home-content {
    .carousel-container {
      overflow: hidden;
      margin-bottom: 30px;
      .ant-carousel {
        width: 83%;
        .slick-initialized {
          .slick-list {
            overflow: visible;
            .slick-slide {
              padding: 10px 30px 0 0;
              .option-card {
                height: 190px;
              }
            }
          }
        }
      }
    }
  }
}


@import '../../../../style/variables.less';
@import '../../../../style/mixins.less';

.story-row {
  display: flex;
  width: 100%;
  gap: 16px;
  margin-bottom: 20px;
}

.option-card-story {
  width: calc(25% - 16px);
  height: 64px;
  display: flex;

  padding: 5px 0;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  cursor: pointer;
  color: @title-dark-grey-color;

  .media-max(610px, {
    width: 100px;
    font-size: 12px;
  });

.media-max(450px, {
  font-size: 10px;
});

.story-title {
  font-size: '20px';
  margin-bottom: '16px';
}

.story-subTitle {
  font-size: '14px';
}

.icon-container {
  align-items: center;
  justify-content: center;
}

.icon svg {
  color: @icon-light-grey-color;
  width: 25px;
  height: 25px;
}

p {
  margin: 0;
}
}

.story-active {
  .icon {
    svg {
      color: @primary-color;
    }
  }

  color: @text-color;
  font-weight: 500;
  border: 1px solid @primary-color;
}

.option-container {
  .mixed-ul {
    li {
      font-weight: bold;
    }

    p {
      margin-bottom: 8px;
    }
  }

  .mixed-ul {
    li {
      font-weight: bold;
    }

    p {
      margin-bottom: 8px;
    }
  }

  ul {
    padding-left: 13px;
    margin-bottom: 15px;
  }
}

.story-input-container {
  background-color: white;
  border-radius: 8px;
  height: 46px;
  display: flex;
  width: calc(100% - 32px);
  padding: 10px;
  align-items: center;

  .story-input {
    box-shadow: none;
    border: none;
    outline: none;
    margin-right: 10px;
  }

  generate-button {
    width: '132px';
  }
}

@import '../../style/variables.less';

.avatars-filter-popover {
  width: 413px;

  .ant-popover-inner {
    padding: 32px;
    border-radius: @border-radius-middle;

    .popover-content {
      .scrollbar {
        height: 558px !important;
      }

      .filters-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .filter-title {
        font-size: 14px;
        line-height: normal;
        margin-bottom: 8px;
      }

      .filter-select {
        width: 100% !important;

        .icon {
          pointer-events: none;
          color: @text-color;
          font-size: 16px;
        }
      }

      .options-container {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .option {
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 10px;
          box-sizing: border-box;
          font-size: 12px;
          line-height: 100%;
          height: 32px;
          padding-inline: 10px;
          border: 1px solid @default-border-color;
          border-radius: @default-border-radius;
          transition: all 0.3s;

          .icon {
            display: none;
          }

          &.active {
            color: #ffffff;
            background-color: @primary-color;
            border-color: @primary-color;

            .icon {
              display: flex;
              font-size: 10px;
            }
          }
        }
      }

      .filter-buttons-wrapper {
        margin-top: 32px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        .ant-btn {
          flex-grow: 1;
        }
      }
    }
  }
}


@import '../../../../style/variables.less';
@import '../../../../style/mixins.less';

.elai-academy {
  &.course-preview {
    .academy-content {
      &-wrapper {
        margin: 48px auto 0;

        .media-max(576px, {
          margin: 32px auto 0;
        });
      }

      .section-title {
        text-align: left;
      }

      .what-learn-section,
      .course-content-section {
        .section-title {
          margin-bottom: 40px;

          .media-max(576px, {
            margin-bottom: 32px;
          });
        }
      }

      .overview-section {
        display: flex;
        align-items: center;
        gap: 40px;

        .media-max(768px, {
          flex-direction: column;
        });

        .course-overview-info {
          max-width: 44%;

          .media-max(768px, {
            max-width: 100%;
            width: 100%;
          });

          h1 {
            line-height: 120%;
            margin-bottom: 24px;

            .responsive-font-size(56px, 1048px, {
              .media-min(1048px, {
                font-size: 56px;
              });
              .media-max(992px, {
                font-size: 34px;
              });
              .media-max(768px, {
                font-size: 40px;
                line-height: 48px;
                margin-bottom: 16px;
              });
            });
          }

          .description {
            font-size: 16px;
            line-height: 140%;
            margin-bottom: 24px;

            .media-max(768px, {
              font-size: 16px;
              margin-bottom: 16px;
            });
            .media-max(576px, {
              font-size: 16px;
              line-height: 22.4px;
            });
          }

          .course-duration-metrics {
            display: flex;
            gap: 24px;
            margin-bottom: 32px;

            .metric-item {
              display: flex;
              align-items: center;
              gap: 8px;
              color: @icon-dark-grey-color;

              span {
                font-size: 16px;
                line-height: 22.4px;
              }

              .icon {
                font-size: 24px;
              }
            }
          }
        }

        .course-teaser-wrapper {
          flex: 1 1 auto;
          aspect-ratio: 16/9;
          overflow: hidden;
          border-radius: @border-radius-middle;

          &.mobile {
            display: none;

            .media-max(768px, {
              display: block;
              margin-bottom: 24px
            });
          }

          &.desktop {
            .media-max(768px, {
              display: none;
            });
          }

          .media-max(768px, {
            max-width: 100%;
          });

          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }

      .what-learn-section {
        margin-top: 120px;

        .media-max(992px, {
          margin-top: 80px;
        });

        .learn-items-wrapper {
          display: flex;
          background-color: #ffffff;
          border-radius: @border-radius-middle;
          padding: 40px 56px;

          .media-max(576px, {
            padding: 40px 24px;
          });

          .ant-row {
            width: 100%;
          }

          .learn-item {
            display: flex;
            align-items: flex-start;

            .icon {
              color: @primary-color;
              margin-top: 4px;
              margin-right: 24px;

              .media-max(576px, {
                margin-right: 16px;
              });
            }

            .item-text {
              font-size: 20px;
              line-height: 24px;
              font-weight: 600;
              margin: 0;

              .media-max(576px, {
                font-size: 18px;
                line-height: 21.6px;
              });
            }
          }
        }
      }

      .course-content-section {
        margin-top: 80px;

        .lessons-wrapper {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-auto-flow: column;
          column-gap: 32px;
          row-gap: 16px;

          .media-max(768px, {
            display: flex;
            flex-direction: column;
            gap: 16px;
          });

          .lesson-item {
            display: flex;
            align-items: center;
            gap: 16px;
            background-color: #ffffff;
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            height: 100%;
            min-height: 80px;
            padding: 16px 32px;
            border-radius: @border-radius-middle;

            .media-max(768px, {
              padding: 18px 16px;
            });

            .lesson-number {
              color: @primary-color;
            }

            .lesson-name {
              margin: 0;

              .media-max(768px, {
                font-size: 18px;
                line-height: 21.6px;
              });
            }
          }
        }
      }

      .materials-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 32px;
        background-color: #ffffff;
        border-radius: @border-radius-middle;
        padding: 24px 60px 20px 64px;
        margin-top: 80px;

        .media-max(768px, {
          flex-direction: column;
          padding: 40px 24px 36px;
        });

        .materials-section-info {
          h2 {
            font-size: 2.2rem;
            line-height: 120%;
          }

          .materials-list-wrapper {
            font-size: 20px;
            line-height: 28px;

            ul {
              max-width: 334px;
              padding-inline-start: 30px;

              .media-max(768px, {
                max-width: 100%;
              });

              li {
                margin-bottom: 8px;
              }
            }
          }
        }

        .materials-image {
          display: block;
          width: 35.8%;

          .media-max(768px, {
            width: 72.1%;
            margin-left: auto
          });
        }
      }

      .btn-start-course {
        display: flex;
        max-width: fit-content;
        margin: 48px auto 0;
      }
    }
  }
}


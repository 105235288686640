.ant-modal.pptx-fonts-prompt-modal {
  .ant-modal-content {
    color: rgba(49, 53, 59, 1);
    padding: 40px;
    .ant-modal-title {
      font-size: 24px;
    }
    .ant-modal-body {
      font-size: 14px;
      .subtitle {
        font-size: 14px;
      }
      .list-wrapper {
        padding: 4px;
        background: rgba(189, 189, 189, 0.1);
        border-radius: 8px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }
      .warning {
        display: flex;
        align-items: flex-start;
        padding-top: 16px;
        gap: 8px;
        font-size: 12px;
        font-weight: 400;
        color: rgba(151, 151, 151, 1);
        svg {
          padding-top: 4px;
          fill: rgba(255, 175, 54, 1);
        }
      }
      .footer-question {
        font-weight: 600;
        padding-top: 20px;
      }
    }
  }
}


@import '../../style/variables.less';

.upgrade-modal {
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-header {
    border-bottom: 0;
    padding: 16px;
    margin: 0;
  }
  .upgrade-modal-scrollbar {
    height: 650px !important;
  }
  .ant-modal-body {
    padding: 40px 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    .mobile {
      display: none !important;
    }
    .ant-row {
      width: 82%;
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .ant-col {
      display: flex;
      flex-direction: column;
      &:first-child {
        & > h3 {
          font-size: 24px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.011em;
          text-align: center;
          margin-bottom: 0;
        }
        .ant-switch {
          align-self: flex-end;
        }
      }
      &.advanced-col {
        .btn-open {
          border: 2px solid @primary-color;
          border-top: none;
        }
      }
    }
    .ant-card {
      cursor: pointer;
      border-radius: @border-radius-large;
      .ant-card-body {
        padding: 25px 30px;
        .main-content {
          display: flex;
          flex-direction: column;
          min-height: 240px;
          h2 {
            font-size: 30px;
            font-weight: 700;
            line-height: 35px;
            text-align: center;
          }
          h3 {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 34px;
            font-weight: 500;
            line-height: 40px;
            text-align: center;
            span {
              font-size: 14px;
              font-weight: 400;
              margin-left: 4px;
            }
          }
          p {
            font-size: 11px;
          }
          .price-annually {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            .ant-typography-secondary {
              font-size: 12px;
              font-weight: 500;
            }
            .discount-text {
              display: inline-block;
              font-size: 12px;
              color: #000;
              background-color: rgba(72, 104, 255, 0.2);
              padding: 3px 8px;
              margin-left: 3px;
            }
          }
          .ant-select {
            margin-bottom: 16px;
          }
          .btn-arrow {
            justify-self: flex-end;
            display: flex;
            justify-content: center;
            width: 100%;
            border-radius: @border-radius-large;
          }
          .btn-login {
            margin-top: auto;
            margin-bottom: 12px;
          }
        }
      }
      &.advanced {
        border: none;
        background-color: @primary-color;
        & > .ant-card-body {
          padding: 12px 0 0 0;
          position: relative;
          overflow: visible;
          h5 {
            color: #ffffff;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: center;
          }
          & > img {
            position: absolute;
            top: -24px;
            right: -12px;
          }
          .ant-card {
            border: 2px @primary-color solid;
          }
        }
      }
      &.custom {
        h4 {
          font-size: 22px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          margin: 10px 0 18px;
        }
        .ant-card-body .main-content > p:nth-child(3) {
          color: rgba(0, 0, 0, 0.88);
          text-align: center;
        }
      }
    }
    .discount {
      position: absolute;
      top: 25px;
      right: 0px;
      &-text {
        color: #ffffff;
        background-color: #1ea65e;
        padding: 0px 8px;
        border-radius: 15px;
      }
      img {
        margin: 5px 0 0 55px;
      }
    }
    .plans-table-container {
      margin-top: 50px;
      .ant-table-wrapper {
        .ant-table {
          overflow: hidden;
          .ant-table-thead {
            display: none;
          }
          .plans-table-row {
            &:hover {
              td {
                background: #ffffff;
              }
            }
          }
          td {
            font-weight: 500;
            border: none;
          }
          .ant-typography-secondary {
            display: block;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0em;
          }
        }
        &:first-child {
          .plans-table-row:first-child {
            .ant-typography-secondary {
              font-size: 20px;
            }
          }
          .plans-table-row:not(:nth-child(-n + 2)):nth-child(odd) {
            td {
              background: #f3fcff;
            }
          }
        }
        &:not(:first-child) {
          .plans-table-row:nth-child(even):not(:first-child) {
            td {
              background: #f3fcff;
            }
          }
        }
      }
    }
    .custom-plan {
      margin-top: 20px;
      p {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0;
        a {
          color: @text-color;
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .upgrade-modal {
    .ant-modal-body {
      .ant-row {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .upgrade-modal {
    .ant-modal-body {
      .ant-row {
        width: 60%;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .upgrade-modal {
    .upgrade-modal-scrollbar {
      height: 750px !important;
    }
    .ant-modal-body {
      padding: 40px 16px 16px;
      .desktop {
        display: none !important;
      }
      .mobile {
        display: block !important;
        h3 {
          text-align: center;
          font-size: 20px;
          font-weight: 500;
        }
        &-switch-container {
          position: relative;
          display: flex;
          justify-content: space-around;
          padding-top: 70px;
          span {
            display: inline;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
          }
          .ant-switch {
            margin-top: 10px;
          }
        }
      }
      .ant-row {
        width: 100%;
      }
      .ant-card {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        &.opened {
          .ant-card-body .plans-table-container {
            max-height: 1500px;
          }
        }
        &.advanced > .ant-card-body .ant-card {
          border-bottom: none;
        }
        .ant-card-body {
          padding: 30px 0 0 0;
          .main-content {
            padding: 0 40px;
          }
          .plans-table-container {
            max-height: 275px;
            overflow-y: hidden;
            border-top: 1px solid @card-border-color;
            padding: 0 20px 20px;
            transition: max-height 0.4s linear;
            .ant-table-wrapper {
              &:first-child {
                .ant-table .plans-table-row:first-child {
                  display: none;
                }
              }
              .ant-table {
                .plans-table-row {
                  td {
                    background: #ffffff !important;
                    padding: 5px;
                    .ant-typography-secondary {
                      margin-top: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .btn-open {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        background-color: @background-color;
        border-radius: 0 0 @border-radius-large @border-radius-large;
        border: 1px solid @card-border-color;
        padding: 15px 25px;
        & > * {
          display: block;
        }
      }
      .custom-plan {
        margin-top: 0;
        p {
          font-weight: 400;
          a {
            font-weight: 500;
          }
        }
      }
    }
  }
}


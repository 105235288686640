@import '../../style/variables.less';

.render-popover {
  width: 320px;

  .ant-popover-content .ant-popover-inner {
    border-radius: @default-border-radius;
    padding: 0;

    .container {
      padding: 24px 24px 32px;

      .alert-message {
        display: flex;
        align-items: flex-start;
        margin-bottom: 24px;

        & > span {
          margin: 2px 10px 0 0;

          svg {
            width: 20px;
            height: 20px;
          }
        }

        p {
          font-size: 12px;
          line-height: 17px;
          margin-bottom: 0;
          word-break: break-word;

          a {
            color: @primary-color;
            font-weight: 700;
          }
        }
      }

      .ant-typography-secondary {
        display: block;
        font-size: 12px;
        line-height: 13.2px;
        margin-bottom: 12px;
      }

      .option-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        p {
          margin: 0;
        }

        span {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
        }
      }

      .ant-divider {
        border-block-start: 2px solid rgba(189, 189, 189, 0.1);
        margin: 16px -24px;
        width: 320px;
      }

      .ant-segmented {
        margin-bottom: 27px;
      }
      #segmented-subtitles-helper .ant-segmented-item-label {
        padding: 0 15px;
      }
      .ant-badge-count {
        display: flex;
        align-items: center;
        color: @primary-color;
        font-weight: 700;
        background-color: #ffffff;
        border-radius: 4px;
        padding: 0 4px;
        font-size: 10px;
        line-height: 14px;
        height: 16px;
        transform: translate(8%, -80%);
        box-shadow: -2px 2px 8px 0px rgba(49, 53, 59, 0.122);
      }

      .ant-btn-primary {
        display: block;
        margin: 0 auto;
      }
    }
  }
}


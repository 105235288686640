.ant-card.preview-main-card {
  width: 100%;
}
.ant-card.preview-main-card, .ant-card.preview-guest-card {
  .ant-card-body {
    iframe {
      width: 100%;
      max-height: 449px;
      max-width: 799px;
    }
  }
}


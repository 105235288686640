@import '../../../../style/variables.less';

.public-avatars {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    background-color: transparent;
    .header {
      width: 100%;
      h1 {
        font-size: 45px;
        font-weight: 700;
        line-height: 50.4px;
        text-align: center;
        margin: 20px 0 16px;
      }
      .anticon {
        display: none;
        color: @title-dark-grey-color;
        font-size: 30px;
        text-align: left;
        margin-left: 10px;
      }
    }

    .tabs-container {
      width: 80%;
      margin: 0 auto;
      .tab-card {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 307px;
        height: 100%;
        background-color: #fff;
        border-radius: 20px;
        padding: 18px;
        margin-top: 4px;
        h3 {
          font-size: 22px;
          font-weight: 600;
          line-height: 24px;
        }
        p {
          font-size: 14px;
          line-height: 16.8px;
          margin: 0;
        }
        .card-image {
          display: block;
          border-radius: @border-radius-large;
          align-self: end;
          justify-self: end;
          width: 60%;
          margin-top: auto;
        }
        .btn-arrow {
          display: none;
        }
        &.active {
          background-color: @primary-color;
          transform: scale(1.03);
          margin-bottom: 4px;
          margin-top: 0px;
          transition: transform 0.3s ease-in-out, background-color 0.2s ease-in-out, margin-bottom 0.3s ease-in-out;
          & > * {
            color: #fff;
            transition: color 0.2s ease-in-out;
          }
        }
      }
    }

    .tabs-container-carousel {
      position: relative;
      display: none;
      width: 100%;
      margin-bottom: 50px;
      .tab-card {
        display: flex !important;
        width: 75% !important;
        min-height: 407px;
        padding: 24px;
        margin: 0 auto;
        h3 {
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 14px;
        }
        p {
          font-size: 16px;
          line-height: 22px;
        }
        .card-image {
          width: 60%;
        }
        &.active {
          min-height: 350px;
          transform: none;
          margin-bottom: 0;
          margin-top: 0;
          .btn-arrow {
            display: none;
          }
        }
        .btn-arrow {
          display: flex;
          color: #ffffff;
          justify-content: center;
          margin-top: 20px;
          padding: 0;
        }
      }
      .ant-carousel {
        .slick-dots {
          position: absolute;
          display: flex;
          align-items: center;
          bottom: -45px;
          li {
            width: 8px;
            height: 8px;
            margin: 0 7px;
            button {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: @default-border-color;
              opacity: 1;
            }
            &.slick-active {
              width: 10px;
              height: 10px;
              button {
                width: 10px;
                height: 10px;
                background-color: @primary-color;
              }
            }
          }
        }
      }
    }
  }

  .tabs-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    margin-top: 60px;
    &-wrapper {
      width: 100%;
      margin: 0 auto;
      .btn-get-started {
        display: block;
        margin: 48px auto 0;
      }
    }
    .main-description {
      max-width: 1020px;
      margin: 0 auto !important;
      .cards-container {
        .card {
          min-height: 170px;
          height: 100%;
          background-color: #fff;
          font-size: 14px;
          line-height: 18px;
          padding: 18px;
          border-radius: @border-radius-large;
          p {
            margin-bottom: 0;
          }
        }
      }
      .card-title {
        display: flex;
        flex-direction: column;
        position: relative;
        min-height: 540px;
        height: 100%;
        padding: 40px;
        border-radius: @border-radius-large;
        overflow: hidden;
        .video-container {
          position: relative;
          width: 100%;
          overflow: hidden;
          padding-top: 56.25%;
          margin-top: auto;
          border-radius: @border-radius-large;
          .youtube-video {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border: none;
          }
        }
        & > * {
          color: #fff;
        }
        h2 {
          font-size: 30px;
          font-weight: 600;
          line-height: 2rem;
          margin-bottom: 12px;
        }
        p {
          width: 80%;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 30px;
        }
        &.studio {
          background-color: #775fc0;
        }
        &.selfie {
          background-color: #979797;
        }
        &.mascot {
          background-color: #e5a935;
        }
        &.photo {
          background-color: @primary-color;
        }
      }
    }
    .examples {
      max-width: @max-landing-wrapper-width;
      background-color: #ffffff;
      padding: 40px 60px 60px;
      border-radius: @border-radius-large;
      &-videos {
        .example-wrapper {
          position: relative;
          video {
            border-radius: @border-radius-large;
          }
          p {
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            margin-top: 10px;
          }
          .complany-logo {
            position: absolute;
            top: 18px;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px 9px;
            width: 61px;
            border: 1px solid rgba(239, 239, 239, 0.6);
            border-radius: 4px 0px 0px 4px;
            background: linear-gradient(46deg, rgba(239, 239, 239, 0.6) 0%, rgba(239, 239, 239, 0.1) 100%);
            backdrop-filter: blur(25px);
          }
        }
      }
      &.collapsible {
        .examples-videos {
          overflow: hidden;
          transition: max-height 0.4s ease-in-out;
          &.mascot {
            max-height: 410px;
          }
          &.photo {
            max-height: 210px;
          }
          &.mascot,
          &.photo {
            &.open {
              max-height: 3000px;
            }
          }
        }
        .btn-arrow {
          display: flex;
        }
      }
      h2 {
        font-size: 30px;
        font-weight: 600;
        line-height: 2rem;
        margin-bottom: 30px;
      }
      .btn-arrow {
        display: none;
        min-width: 131px;
        margin: 36px auto 0;
      }
    }

    .instruction {
      width: 75%;
      background-color: #fff;
      padding: 40px 50px;
      margin: 0 auto;
      border-radius: @border-radius-large;
      h2 {
        font-size: 30px;
        font-weight: 600;
        line-height: 2rem;
      }
      & > p {
        font-size: 16px;
        a {
          text-decoration: underline;
        }
      }
      .steps-container {
        margin-top: 30px;
        .step-card {
          height: 100%;
          background-color: @background-color-grey;
          padding: 20px 0;
          border-radius: @border-radius-large;
          .image-frame {
            width: 68%;
            height: 125px;
            background-color: #c3c3c3;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: @border-radius-large;
            margin: 0 auto 16px;
          }
          p {
            width: 68%;
            font-size: 14px;
            font-weight: 500;
            line-height: 1rem;
            text-align: center;
            margin: 0 auto;
          }
        }
      }
      .btn-login {
        margin-top: 35px;
      }
    }

    .ant-collapse.voice-collapse {
      width: 100%;
      max-width: @max-landing-wrapper-width;
      background-color: #ffffff;
      border: none;
      border-radius: @border-radius-large;
      margin: 0 auto;
      & > .ant-collapse-item {
        border-bottom: none;
        &:last-child > .ant-collapse-header {
          display: flex;
          flex-direction: column-reverse;
          padding: 50px;
          .ant-collapse-expand-icon {
            width: 100%;
            height: 80px;
            padding: 0;
            .btn-arrow {
              color: #ffffff;
              min-width: 123px;
            }
          }
          .ant-collapse-header-text {
            width: 100%;
            .header-container {
              position: relative;
              h2 {
                width: 50%;
                font-size: 30px;
                font-weight: 600;
                line-height: 2.5rem;
                text-align: center;
                margin: 0 auto 20px;
              }
              img {
                position: absolute;
                &.stick-shape {
                  width: 15%;
                  top: -2%;
                  left: 6%;
                }
                &.triangle-shape {
                  width: 15%;
                  top: -40%;
                  right: 0;
                }
              }
            }
          }
          .btn-arrow {
            position: relative;
            top: 0;
            right: 0;
            left: 0;
            transform: none;
            display: flex !important;
            font-size: 16px;
            margin: 40px auto 0;
          }
        }
        .ant-collapse-content {
          background-color: #ffffff;
          border-radius: @border-radius-large;
          border-top: none;
          padding: 0;
          &-box {
            padding: 0;
            .voice-container {
              padding-top: 0;
            }
          }
        }
      }
    }

    .purchase {
      max-width: 620px;
      & > h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 2rem;
        text-align: center;
        margin-bottom: 20px;
      }
      & > p {
        color: #31353b;
        font-size: 16px;
        line-height: 1.2rem;
        text-align: center;
        margin-bottom: 40px;
      }
      & > .ant-row {
        margin: 0 auto;
        .ant-col:first-child {
          position: relative;
        }
      }
      .ant-card {
        border-radius: @border-radius-large;
        border: none;
        overflow: hidden;
        height: 100%;
        .ant-card-body {
          display: flex;
          flex-direction: column;
          padding: 30px 40px;
          height: 100%;
          h2 {
            font-size: 26px;
            font-weight: 400;
            line-height: 1.8rem;
            margin-bottom: 10px;
          }
          h3 {
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 0;
            span {
              font-size: 12px;
              font-weight: 400;
            }
          }
          & > p {
            font-size: 0.7rem;
            color: #efab2e;
            font-weight: 500;
            margin-bottom: 2px;
          }
          ul {
            padding: 15px;
            margin: 0;
            flex-grow: 1;
            li {
              margin-bottom: 16px;
            }
          }
          .btn-login {
            margin-top: auto;
          }
        }
        &.active {
          .ant-card-body {
            color: #ffffff;
            background-color: @primary-color;
            h2,
            h3 {
              color: #ffffff;
            }
            .btn-arrow {
              background-color: @btn-primary-hover-color;
              border-color: @btn-primary-hover-color;
              &:hover,
              &:focus {
                background-color: #393d43;
                border-color: #393d43;
              }
            }
          }
        }
      }
      .mobile {
        display: none;
        &.active > .ant-card-body {
          padding: 24px;
          h2 {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 14px;
          }
          & > p {
            margin-left: 52%;
          }
          .plans-cards-container {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            margin-bottom: 16px;
            & > div {
              width: 48.5%;
            }
            .ant-card {
              cursor: pointer;
              height: auto;
              border-radius: @default-border-radius;
              &-body {
                height: 100%;
                background-color: #ffffff;
                padding: 10px;
                h4 {
                  font-size: 12.5px;
                  line-height: 0.9rem;
                }
                p {
                  font-size: 18px;
                  margin-top: auto;
                  margin-bottom: 0;
                  span {
                    font-size: 12.5px;
                  }
                }
                h4,
                p {
                  color: @text-color;
                }
              }
              &.active {
                .ant-card-body {
                  background-color: @btn-primary-hover-color;
                  h4,
                  p {
                    color: #ffffff;
                  }
                }
              }
            }
          }
          .btn-arrow {
            color: @btn-primary-hover-color;
            border-color: #ffffff;
            background-color: #ffffff;
            width: 100%;
            justify-content: center;
            &:focus,
            &:hover {
              color: @btn-primary-hover-color;
              border-color: #ffffff;
              background-color: #ffffff;
            }
          }
        }
      }
      .discount {
        position: absolute;
        top: -35px;
        right: -80px;
        &-text {
          color: #ffffff;
          background-color: #1ea65e;
          padding: 0px 8px;
          border-radius: 15px;
        }
        img {
          margin: 5px 0 0 8px;
        }
      }
    }
  }
}

.example-avatar-modal {
  .ant-modal-content {
    padding-top: 50px;
    .example-video {
      max-width: 1000px;
      max-height: 700px;
    }
  }
}

@media screen and (max-width: 1040px) {
  .public-avatars {
    .tabs-content-wrapper {
      width: 90%;
    }
  }
}

@media screen and (max-width: 992px) {
  .public-avatars {
    .tabs-content {
      .instruction {
        width: 100%;
      }
      .purchase {
        width: 70%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .public-avatars {
    .content {
      gap: 50px;
      .header {
        h1 {
          font-size: 40px;
          line-height: 48px;
          margin: 100px 0 20px;
        }
        p {
          width: 70%;
        }
      }
    }
    .tabs-content {
      .main-description {
        .card-title {
          h2 {
            font-size: 32px;
            line-height: 2.5rem;
          }
          p {
            font-size: 16px;
            line-height: 1.4rem;
          }
        }
      }
      .instruction {
        width: 100%;
        background-color: transparent;
        padding: 0;
        h2 {
          margin-bottom: 20px;
          text-align: center;
        }
        & > p {
          text-align: center;
        }
        .steps-container {
          max-width: 380px;
          margin: 0 auto !important;
          .step-card {
            background-color: #ffffff;
            .image-frame {
              width: 70%;
              height: 140px;
            }
            p {
              width: 90%;
            }
          }
        }
      }
      .ant-collapse.voice-collapse {
        & > .ant-collapse-item {
          &:last-child > .ant-collapse-header {
            padding: 25px;
            .ant-collapse-expand-icon {
              width: 100%;
              height: 80px;
            }
            .ant-collapse-header-text {
              width: 100%;
              .header-container {
                h2 {
                  width: 100%;
                }
                img {
                  display: none;
                }
              }
            }
            .btn-arrow {
              position: relative;
              top: 0;
              right: 0;
              left: 0;
              transform: none;
              display: flex !important;
              font-size: 16px;
              margin: 40px auto 0;
            }
          }
          .ant-collapse-content {
            padding: 0;
            .steps .cards-container .step-card {
              padding: 10px;
            }
          }
        }
      }
      .purchase {
        h2 {
          font-size: 32px;
          line-height: 2.5rem;
          margin-bottom: 20px;
        }
        p {
          font-size: 16px;
          line-height: 1.4rem;
        }
        .discount {
          top: -10px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .public-avatars {
    .content {
      gap: 40px;
      .header {
        h1 {
          font-size: 32px;
          line-height: 38.4px;
          margin: 30px 20px 20px;
        }
        .anticon {
          display: block;
        }
      }
      .tabs-container {
        display: none;
      }
      .tabs-container-carousel {
        display: block;
      }
    }
    .tabs-content {
      .main-description {
        .cards-container {
          .card {
            min-height: 100px;
          }
        }
        .card-title {
          min-height: 300px;
          padding: 24px;
        }
      }
      .examples {
        padding: 30px;
      }
      .purchase {
        width: 90%;
        .desktop {
          display: none;
        }
        .mobile {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .public-avatars {
    .tabs-content {
      .main-description {
        .card-title {
          p {
            width: 100%;
          }
        }
      }
    }
  }
}


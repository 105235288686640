@import '../../../../../../../style/variables.less';

.phoneme-modal {
  .ant-modal-content {
    padding: 20px 40px 30px;

    h2 {
      font-size: 24px;
      line-height: 120%;
      margin: 20px 0 16px;
    }

    p.subheader {
      line-height: 140%;
      margin-bottom: 24px;
    }

    .btn-add {
      margin-bottom: 16px;
    }

    .input-search {
      margin-bottom: 16px;
    }

    .ant-table-wrapper {
      min-height: 422px;

      .ant-table {
        .ant-table-tbody .ant-table-row .ant-table-cell {
          padding: 10px 16px;

          &:not(:last-child) {
            vertical-align: top;
          }
        }

        .editable-cell-value-wrap,
        .ant-form-item .ant-input {
          box-sizing: border-box;
          min-height: 32px;
        }

        .table-actions-wrapper {
          display: flex;
          justify-content: center;

          .ant-space-item>span {
            cursor: pointer;
            display: flex;
            align-items: center;
          }

          .icon {
            font-size: 18px;
          }
        }
      }

      .ant-table-pagination.ant-pagination {
        margin-bottom: 0;
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      .info-wrapper {
        display: flex;
        align-items: center;

        &>span {
          display: block;

          &:first-child {
            display: flex;
            align-items: center;
            margin-right: 5px;
          }
        }

        .icon {
          color: @icon-grey-color;
          font-size: 18px;
        }
      }
    }
  }
}

.phone-language-selector {
  width: 100%;
}

.phoneme-preview-disabled {
  cursor: not-allowed !important;
  color: @icon-light-grey-color;
}

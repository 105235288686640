@import '../../../../style/variables.less';

.public-voice {
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    margin-top: 10px;
    &-wrapper {
      width: 100%;
      margin: 0 auto;
    }

    .section-title {
      font-size: 30px;
      font-weight: 600;
      line-height: 2.5rem;
    }

    .btn-buy-now {
      display: block;
      margin: 48px auto 0;
    }

    .btn-get-started,
    .btn-get-started button {
      display: flex;
      align-items: center;
      height: auto;
      font-size: 18px;
      line-height: 26px;
      padding: 0;
    }

    .header {
      max-width: 428px;
      h1 {
        font-size: 45px;
        font-weight: 700;
        line-height: 50.4px;
        text-align: center;
        margin: 20px 0 16px;
      }
    }

    .languages {
      &-container {
        width: 100%;
        padding: 90px;
        background: url('../../../../assets/images/public_custom_voice/languages-background.png') center/100% no-repeat;
      }
      &-content {
        max-width: 320px;
        &-wrapper {
          max-width: 900px;
          background: #ffffff url('../../../../assets/images/public_custom_voice/microphone.png') right/cover no-repeat;
          padding: 44px 60px;
          border-radius: @border-radius-large;
          margin: 0 auto;
        }
        .section-title {
          line-height: 120%;
          margin-bottom: 24px;
          span {
            color: @primary-color;
          }
        }
        p {
          color: @icon-dark-grey-color;
          line-height: 140%;
          margin-bottom: 22px;
        }
      }
    }

    .avatars-container {
      max-width: @max-landing-wrapper-width;
      .section-title {
        max-width: 400px;
        text-align: center;
        margin: 0 auto 36px;
      }
      .cards-container {
        .avatar-card {
          background-color: #ffffff;
          height: 100%;
          border-radius: @border-radius-large;
          &.selfie-avatar {
            display: flex;
            flex-direction: column;
            padding: 35px 30px 24px;
            p {
              max-width: 250px;
              margin-bottom: 28px;
            }
            img {
              display: block;
              max-width: 165px;
              border-radius: @border-radius-large;
              margin: 0 auto 34px;
            }
            a {
              margin-top: auto;
              align-self: flex-end;
            }
          }
          &.studio-avatar {
            background: #ffffff url('../../../../assets/images/public_custom_voice/studio-avatar.png') right 20px bottom
              0px/37% no-repeat;
            padding: 24px 40px 24px;
            p {
              max-width: 350px;
              margin-bottom: 18px;
            }
          }
          &.photo-avatar,
          &.cartoon-avatar {
            display: flex;
            flex-direction: column;
            padding: 24px 18px;
            .card-content-container {
              display: flex;
              p {
                max-width: 150px;
                margin-right: 18px;
              }
              img {
                width: 90px;
                height: 90px;
                border-radius: @border-radius-middle;
              }
            }
            a {
              margin-top: auto;
            }
          }
          h3 {
            font-size: 18px;
            line-height: 1.4rem;
            margin-bottom: 8px;
          }
          p {
            color: @icon-dark-grey-color;
            font-size: 14px;
            line-height: 1.35rem;
            margin-bottom: 0;
          }
        }
      }
    }

    .purchase {
      &-container {
        width: 65%;
        max-width: 800px;
        .section-title {
          text-align: center;
          margin-bottom: 36px;
        }
      }
      &-content-container {
        display: flex;
        align-items: center;
        gap: 28px;
        background-color: #ffffff;
        padding: 36px 24px;
        border-radius: @border-radius-large;
        .img-megaphone {
          max-width: 380px;
          width: 100%;
          &-container {
            flex: 1 1 50%;
          }
        }
        .main-content {
          line-height: 1.57rem;
          flex: 1 1 50%;
          h2 {
            font-size: 26px;
            font-weight: 400;
            line-height: 1.8rem;
            margin-bottom: 20px;
          }
          h3 {
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 16px;
          }
          ul {
            max-width: 240px;
            padding: 15px;
            margin: 0;
            li {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  .public-voice {
    &-content .content-wrapper {
      width: 90%;
    }

    .languages-container {
      padding: 0;
      background: transparent;
      .languages-content-wrapper {
        max-width: 1000px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .public-voice {
    &-content {
      .avatars-container .cards-container {
        .avatar-card {
          &.selfie-avatar {
            a {
              flex: 0 0 100%;
              justify-self: flex-start;
              margin-top: 0;
            }
          }
          &.studio-avatar {
            background-size: 28%;
          }
          &.photo-avatar,
          &.cartoon-avatar,
          &.selfie-avatar {
            padding: 24px;
            .card-content-container {
              display: flex;
              flex-direction: column;
              flex: 1 1 100%;
              p {
                max-width: 200px;
                margin-right: 0;
                margin-bottom: 16px;
              }
              img {
                display: block;
                max-width: none;
                width: 112px;
                height: 112px;
                border-radius: @border-radius-middle;
                margin: auto auto 16px;
              }
            }
            a {
              margin-top: 0;
              align-self: flex-start;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .public-voice {
    &-content {
      .header {
        h1 {
          font-size: 40px;
          line-height: 48px;
          margin: 100px 0 20px;
        }
        p {
          width: 70%;
        }
      }

      .languages-container .languages-content-wrapper {
        background: #ffffff;
        .languages-content {
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .public-voice {
    &-content {
      .avatars,
      .languages,
      .purchase {
        &-container .section-title {
          font-size: 24px;
          line-height: 120%;
          margin-bottom: 24px;
        }
      }
      .header {
        h1 {
          font-size: 32px;
          line-height: 38.4px;
          margin: 30px 20px 20px;
        }
      }

      .languages-container .languages-content-wrapper {
        padding: 30px 50px;
      }

      .avatars-container .cards-container {
        .avatar-card {
          &.studio-avatar {
            background-size: 55%;
            background-position: right 0 bottom;
            padding: 24px;
            p {
              max-width: 200px;
            }
          }
          &.photo-avatar,
          &.cartoon-avatar,
          &.selfie-avatar {
            .card-content-container {
              p {
                max-width: 300px;
              }
              img {
                width: 60%;
                height: auto;
              }
            }
          }
        }
      }

      .purchase {
        &-content-container {
          .img-megaphone {
            &-container {
              display: none;
            }
          }
          .main-content {
            flex: 1 1 100%;
            ul {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}


@import '../../style/variables.less';

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px;
  .progress {
    width: 50%;
    margin-bottom: 20px;
  }
  .avatar-card {
    position: relative;
    background-color: #ffffff;
    border-radius: @default-border-radius;
    padding: 0;
    overflow: hidden;
    isolation: isolate;
    .title {
      background-color: @primary-color;
      padding: 15px;
      h2 {
        color: #ffffff;
        font-size: 28px;
        font-weight: 700;
        line-height: 38px;
        text-align: center;
        margin: 0;
      }
    }
    .main {
      padding: 50px 70px;
      h3 {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
      }
      .ant-typography-secondary {
        display: block;
        text-align: center;
        width: 45%;
        margin: 0 auto 30px;
      }
      .ant-steps .wait-icon {
        cursor: pointer;
      }
      .btns-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
      }
      .btn-login {
        margin: 50px auto 0;
      }

      .video-container {
        position: relative;
        width: 100%;
        overflow: hidden;
        padding-top: 60%;
        margin-top: auto;
        border-radius: @default-border-radius;
        .youtube-video {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          border: none;
        }
      }
      .instruction-list {
        list-style: none;
        padding: 0 20px 0 55px;
        li {
          margin-bottom: 12px;
          position: relative;
          &::before {
            content: '\2022';
            font-size: 25px;
            font-weight: bold;
            color: @primary-color;
            position: absolute;
            top: -10px;
            left: -16px;
          }
          span {
            font-weight: 500;
          }
        }
        a {
          font-weight: 600;
        }
      }
      .ant-form {
        .ant-form-item:last-child {
          margin-bottom: 0;
        }
        .ant-form-item-control-input-content {
          margin-top: 0;
        }
        .ant-upload-list-text .ant-upload-list-item-name {
          width: 300px;
        }
        .turnstile-captcha {
          margin-bottom: 16px;
        }
      }
      .ant-timeline .ant-timeline-item:not(:last-child) {
        padding-bottom: 50px;
      }
    }
    &.voice {
      margin-top: 60px;
      .steps-shape-img {
        top: auto;
        bottom: -75px;
        left: 20px;
        width: 150px;
        transform: rotate(-90deg);
      }
    }
    .steps-shape-img {
      position: absolute;
      top: 170px;
      width: 100px;
      z-index: -1;
    }
  }
}

@media screen and (max-width: 576px) {
  .container {
    .progress {
      width: 100%;
    }
    .avatar-card {
      .title {
        padding: 10px;
        h2 {
          font-size: 20px;
        }
      }
      .main {
        padding: 20px;
        .ant-typography-secondary {
          width: 100%;
        }
        .instruction-list {
          padding-left: 20px;
        }
      }
      .steps-shape-img {
        display: none;
      }
    }
  }
}


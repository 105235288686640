@import '../../style/variables.less';
@import '../../style/mixins.less';

.elai-academy {
  .academy-content {
    &-wrapper {
      max-width: @max-landing-wrapper-width;
      margin: 114px auto 0;

      .media-max(1048px, {
        margin: 48px auto 0;
        padding: 0 24px;
      });
      .media-max(576px, {
        margin: 32px auto 0;
      });

      .course-breadcrumb {
        margin-bottom: 56px;

        .media-max(576px, {
          font-size: 13px;
          line-height: 15.6px;
          margin-bottom: 40px;
        });
      }
    }

    .section-title {
      font-size: 40px;
      line-height: 120%;
      text-align: center;

      .media-max(576px, {
        font-size: 32px;
        line-height: 38.4px;
      });
    }

    .btn-arrow-xl {
      display: inline-flex;
      font-size: 18px;
      line-height: 28px;
      height: 48px;
      min-width: 190px;
    }

    .title-block {
      display: flex;
      position: relative;
      border-radius: @border-radius-large;
      background-image: url('../../assets/images/academy/title-block-background.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      aspect-ratio: 125/62;
      padding: 80px 88px;
      overflow: hidden;
      margin-bottom: 88px;

      .media-max(992px, {
        padding: 64px 72px;
        margin-bottom: 48px;
      });
      .media-max(768px, {
        padding: 30px 52px;
      });
      .media-max(576px, {
        flex-direction: column;
        background-image: url('../../assets/images/academy/title-block-background-mobile.jpg');
        align-items: center;
        aspect-ratio: 5/9;
        padding: 40px 16px;
      });

      &-content {
        color: #ffffff;
        max-width: 384px;

        .media-max(768px, {
          max-width: 300px;
          margin: auto 0;
        });
        .media-max(576px, {
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 100%;
          margin: 0;
        });

        h1 {
          line-height: 120%;
          margin-bottom: 24px;

          .responsive-font-size(56px, 1048px, {
            .media-min(1048px, {
              font-size: 56px;
            });
            .media-max(992px, {
              font-size: 34px;
            });
            .media-max(768px, {
              font-size: 24px;
              margin-bottom: 14px;
            });
            .media-max(576px, {
              font-size: 40px;
              line-height: 48px;
              text-align: center;
              margin-bottom: 16px;
            });
          });
        }

        .description {
          font-size: 16px;
          line-height: 140%;
          max-width: 352px;
          margin-bottom: 32px;

          .media-max(768px, {
            font-size: 16px;
            max-width: 260px;
            margin-bottom: 16px;
          });
          .media-max(576px, {
            font-size: 16px;
            line-height: 22.4px;
            text-align: center;
            max-width: 100%;
            margin-bottom: 24px;
          });
        }

        .btn-arrow {
          color: @dark-color;
          background-color: #ffffff;

          &:hover,
          &:focus {
            background-color: #ffffff;
            border-color: #ffffff;
          }
        }
      }

      .btn-play-circle {
        position: absolute;
        top: 51.2%;
        right: 24.1%;
        font-size: 28px;
        width: 64px;
        height: 64px;

        .media-max(992px, {
          font-size: 22px;
          width: 48px;
          height: 48px;
        });

        .media-max(576px, {
          top: auto;
          bottom: 10.4%;
          left: 50%;
          transform: translateX(-50%);
          font-size: 28px;
          width: 64px;
          height: 64px;
        });
      }
    }

    .courses-list-section {
      .section-title {
        padding-top: 32px;
        margin-bottom: 56px;

        .media-max(576px, {
          padding-top: 16px;
          margin-bottom: 40px;
        });
      }

      .course-card {
        display: flex;
        flex-direction: column;
        height: 100%;
        border-radius: @border-radius-middle;
        border: none;

        .ant-card-cover {
          background-color: @default-border-color;
          aspect-ratio: 16/9;
          border-radius: @border-radius-middle @border-radius-middle 0 0;
          margin: 0;
          overflow: hidden;

          img {
            display: block;
            width: auto;
            height: 100%;
            margin: 0 auto;
            border-radius: 0;
          }
        }

        .ant-card-body {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          padding: 32px 24px;

          .ant-card-meta {
            flex: 1 1 auto;

            .ant-card-meta-detail {
              display: flex;
              flex-direction: column;
              gap: 12px;

              .ant-card-meta-title {
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 0;
                white-space: normal;
              }

              .ant-card-meta-description {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                p {
                  color: @dark-color;
                  line-height: 19.6px;
                  margin-bottom: 24px;
                }

                .btn-link {
                  display: inline-flex;
                  font-size: 16px;
                  line-height: 16px;
                  height: 16px;
                  padding: 0;
                  margin-top: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

.welcome-video-modal {
  .ant-modal-content {
    padding: 0;
    overflow: hidden;

    iframe {
      width: 101%;
      aspect-ratio: 16/9;
      margin-left: -1px;
      margin-bottom: -6px;
    }
  }
}


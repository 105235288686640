@import url('../../../../../style/variables.less');

.purchase-modal {
  .ant-modal-body {
    margin: 0 60px;
    .chat-icon {
      display: block;
      color: @success-color;
      font-size: 64px;
      margin: 32px auto;
    }
    h3 {
      font-size: 20px;
      line-height: 140%;
    }
    .key-points-list {
      list-style: none;
      padding-left: 16px;
      margin-bottom: 0;
      li {
        font-size: 16px;
        line-height: 140%;
        margin-bottom: 8px;
        &::marker {
          content: '• ';
          font-size: 22px;
          color: @primary-color;
        }
      }
    }
    a {
      font-weight: 600;
    }
    .agreement-checkbox {
      display: flex;
      max-width: 400px;
      margin: 16px auto 0;
      .ant-checkbox + span {
        padding-inline-start: 12px;
      }
    }
    .btn-payment {
      display: block;
      margin: 30px auto 20px;
    }
  }
}

@media screen and (max-width: 576px) {
  .purchase-modal {
    .ant-modal-body {
      margin: 0 10px;
      .chat-icon {
        font-size: 50px;
      }
    }
  }
}


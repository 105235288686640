@import '../../style/variables.less';

.comments {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-title {
    margin: 0;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .comments-sorter-select {
        .ant-select-selector {
          padding: 0;
        }
        .ant-select-arrow {
          inset-inline-end: 0;

          .icon {
            pointer-events: none;
            font-size: 16px;
            color: @text-color;
          }
        }
      }
    }
  }

  &-scrollbar {
    flex: 1 1 100%;
  }

  &-message {
    display: block;
    text-align: center;
    margin-top: 32px;
  }

  &-create-section {
    padding: 16px 8px;
    border-radius: 10px;
    background: rgba(189, 189, 189, 0.1);
    margin-top: 20px;

    .controls-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;

      .ant-select.slides-select {
        .ant-select-selector {
          border-radius: @border-radius-small;
          border: none;

          .ant-select-selection-item {
            font-weight: 700;
          }
        }

        .ant-select-arrow .icon {
          pointer-events: none;
          font-size: 16px;
        }

        &:not(.ant-select-disabled) .ant-select-arrow .icon {
          color: @text-color;
        }
      }
    }

    .textarea-container .btn-send .icon {
      transform: rotate(-45deg);
      margin: 0 0 2.5px 2.5px;
    }
  }

  .comment-card {
    position: relative;
    padding: 12px 12px 16px;
    border-radius: @border-radius-small;
    transition: background-color 0.5s;

    &:hover {
      background-color: rgba(217, 217, 217, 0.1);
    }

    &:active {
      background-color: rgba(217, 217, 217, 0.2);
    }

    &.unviewed {
      background-color: rgba(217, 217, 217, 0.4);
    }

    & + .comment-card {
      margin-top: 8px;

      &::before {
        content: '';
        position: absolute;
        top: -5px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: rgba(189, 189, 189, 0.15);
      }
    }

    &-title {
      display: flex;
      align-items: center;
      gap: 14px;
      margin-bottom: 5px;
    }

    .textarea-container .btn-send .icon {
      font-size: 12px;
    }

    .comment {
      &-user-name {
        font-size: 14px;
        font-weight: 600;
        line-height: 140%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 50%;
        margin-bottom: 0;
      }

      &-time {
        display: block;
        flex: 1 1 auto;
        line-height: 140%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 0 0 50px;
        .btn-menu {
          color: @icon-dark-grey-color;
        }
      }

      &-info {
        display: flex;
        align-items: flex-start;
      }

      &-slide-tag {
        margin-inline-end: 10px;
      }

      &-text {
        word-break: break-word;
        line-height: 140%;
        margin-bottom: 0;
      }
    }
  }

  .textarea-container {
    position: relative;
    width: 100%;

    .comment-textarea {
      padding-right: 40px;
    }

    .btn-send {
      position: absolute;
      bottom: 8px;
      right: 8px;

      .icon {
        display: block;
        font-size: 16px;
      }
    }
  }
}

.comments-sorter-dropdown {
  padding: 24px 4px;

  .options-title {
    color: @icon-dark-grey-color;
    font-size: 12px;
    line-height: normal;
    padding: 0 12px;
    margin-bottom: 7px;
  }

  .menu-wrapper {
    margin-bottom: 14px;
  }

  .status-options {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 0 12px;
    margin-top: 12px;

    .ant-radio-wrapper {
      display: flex;
      justify-content: space-between;
      margin-inline-end: 0;

      .ant-radio {
        order: 1;
      }

      span:last-child {
        padding: 0;
      }
    }
  }
}

.spin-comments {
  width: 100%;
  margin-top: 10;
}


@import '../../style/variables.less';

.horizontal-scroll {
  position: relative;
  width: 100%;

  .items-wrapper {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      background: transparent;
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }
  }

  .btn-arrow-mask {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    width: 15%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    .btn-arrow {
      background-color: @btn-primary-hover-color;

      &:focus {
        background-color: @btn-primary-hover-color;
        border-color: @btn-primary-hover-color;
      }

      .icon {
        font-size: 22px;
        margin: 0;
      }
    }

    &.left {
      left: 0;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 100%);

      .btn-arrow {
        margin-left: 5px;
      }
    }

    &.right {
      right: 0;
      justify-content: flex-end;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 100%);

      .btn-arrow {
        margin-right: 5px;
      }
    }
  }
}


.delete-account {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 70px;
  .btn-delete-account {
    display: block;
    //margin-top: 20px;
  }
}

